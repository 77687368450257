import { Box, CircularProgress } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { useGetWinnerById } from 'hooks/api/Sweepstake/useGetWinnerById'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { isIdPresent } from 'utils/typeUtils'
import { WinnerDetailsHeader } from './WinnerDetailsHeader'
import { format, parseISO } from 'date-fns'
import { getRedrawnPatronsColumns } from './RedrawnPatronsColumns'
import { GrandPrizeDetails } from './GrandPrizeDetails'
import { LocationPrizeDetails } from './LocationPrizeDetails'
import { WinnerDetailsSection } from './WinnerDetailsSection'
import { AffidavitSection } from './AffidavitSection'
import { RedrawnPatronsSection } from './RedrawnPatronsSection'
import { useGetNumberWinnerEntriesMultiplePatrons } from 'hooks/api/Sweepstake/EntriesCount/useGetNumberWinnerEntriesMultiplePatrons'
import { useGetRedrawnPatrons } from 'hooks/api/Sweepstake/useGetRedrawnPatrons'
import { formatPhoneNumber } from 'utils/phoneUtils'

export const WinnerDetailsPage = () => {
  const winnerId = Number(useParams().id)
  // Query to get the winner by id
  const getWinnerQuery = useGetWinnerById(winnerId)
  const winner = getWinnerQuery.data
  // Get the LE, sweepstake, and sweepstake drawing id from the winner
  const licensedEstablishment = winner?.sweepstakeDrawing?.licensedEstablishment
  const sweepstake = winner?.sweepstakeDrawing?.contest
  const sweepstakeDrawingId = winner?.sweepstakeDrawingId
  // Query to get the redrawn patrons
  const getRedrawnPatronsQuery = useGetRedrawnPatrons({ sweepstakeDrawingId })
  const redrawnPatronsData = getRedrawnPatronsQuery.data
  // Query to get the number of entries for each redrawn patron
  const entriesByRedrawnPatronQuery = useGetNumberWinnerEntriesMultiplePatrons({
    contestId: winner?.sweepstakeDrawing?.contestId,
    patronIds:
      Array.isArray(redrawnPatronsData) &&
      redrawnPatronsData !== undefined &&
      redrawnPatronsData.length > 0
        ? redrawnPatronsData.map(
            (winner: { patronId: number }) => winner.patronId
          )
        : undefined,
    licensedEstablishmentId:
      winner?.sweepstakeDrawing?.type === 'Location'
        ? undefined
        : licensedEstablishment?.id,
  })
  const entriesList: Record<number, number> | undefined =
    entriesByRedrawnPatronQuery.data

  // Loading spinner
  if (
    getWinnerQuery.isPending ||
    getRedrawnPatronsQuery.isPending ||
    entriesByRedrawnPatronQuery.isPending
  ) {
    return <CircularProgress />
  }

  // Handling query errors
  if (getWinnerQuery.isError) {
    return <p>An error occurred on getWinnerQuery.</p>
  }

  if (getRedrawnPatronsQuery.isError) {
    return <p>An error occurred on getRedrawnPatronsQuery.</p>
  }

  if (entriesByRedrawnPatronQuery.isError) {
    return <p>An error occurred on entriesByRedrawnPatronQuery.</p>
  }

  if (
    isNil(winner) ||
    !isIdPresent(winner) ||
    isNil(sweepstake) ||
    !isIdPresent(sweepstake)
  ) {
    return <p>An error occurred.</p>
  }

  const drawing = winner?.sweepstakeDrawing
  const winnerType = drawing?.type

  const getFullName = (
    firstName?: string | null,
    lastName?: string | null
  ): string => {
    const safeFirstName = firstName ?? ''
    const safeLastName = lastName ?? ''
    const fullName = `${safeFirstName} ${safeLastName}`.trim()
    return fullName || '-'
  }

  const redrawnPatronsRows =
    Array.isArray(redrawnPatronsData) && redrawnPatronsData.length > 0
      ? redrawnPatronsData.flatMap((winner) => {
          const drawingSequence = winner.drawingSequence
          const dob = winner.patron?.dob ?? ''
          // Check if dateRedrawnAt is a valid date
          const dateRedrawnAt = winner.dateRedrawnAt
            ? new Date(winner.dateRedrawnAt)
            : null
          const dateRedrawn =
            dateRedrawnAt && !isNaN(dateRedrawnAt.getTime())
              ? format(dateRedrawnAt, 'MM-dd-yyyy hh:mm aa')
              : '-'
          return {
            id: winner.id,
            drawingNumber:
              typeof drawingSequence === 'number' ? drawingSequence + 1 : -1,
            name: getFullName(
              winner.patron?.firstName,
              winner.patron?.lastName
            ),
            phoneNumber: formatPhoneNumber(winner.patron?.phoneNumber),
            email: winner.patron?.email ?? '-',
            tierLevel: winner.patron?.currentTier?.tier?.type ?? 'Member',
            gender:
              winner.patron?.gender === 'PreferNotToAnswer'
                ? 'Prefer Not To Answer'
                : (winner.patron?.gender ?? '-'),
            birthday: dob ? format(parseISO(dob), 'P') : '-',
            totalEntriesByWinner: entriesList?.[winner.patronId] ?? 0,
            dateRedrawn,
            reasonForRedraw: winner.redrawReason ?? '-',
          }
        })
      : []

  const redrawnPatronsColumns = getRedrawnPatronsColumns()

  return (
    <Page
      header={<WinnerDetailsHeader winner={winner} sweepstakes={sweepstake} />}
    >
      <Box>
        {winnerType === 'Location' && !isNil(licensedEstablishment) && (
          <LocationPrizeDetails
            licensedEstablishment={licensedEstablishment}
            sweepstakeId={winner.sweepstakeDrawing?.contestId ?? 0}
          />
        )}
        {winnerType === 'GrandPrize' && (
          <GrandPrizeDetails
            sweepstakeId={winner.sweepstakeDrawing?.contestId ?? 0}
          />
        )}
        <WinnerDetailsSection winner={winner} />
        <AffidavitSection winner={winner} />
        <RedrawnPatronsSection
          rows={redrawnPatronsRows}
          columns={redrawnPatronsColumns}
        />
      </Box>
    </Page>
  )
}
