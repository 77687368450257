import {
  DataGrid,
  type GridSortModel,
  type DataGridProps,
  type GridValidRowModel,
  type GridColDef,
} from '@mui/x-data-grid'
import { useLayout } from 'hooks/useLayout'
import { MobileDataTable } from 'components/DataTable/MobileDataTable'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import { NoDataIndicator } from 'components/NoDataIndicator/NoDataIndicator'
import { colorPrimitives } from 'components/Theme'

export const DataTable = <RowModelT extends GridValidRowModel>({
  sortFieldName,
  sort,
  noDataMessage = 'No data found',
  forceDesktopTableInMobile = false,
  emptyDataImageSource,
  ...props
}: DataGridProps<RowModelT> & {
  sortFieldName?: string
  sort?: 'asc' | 'desc'
  noDataMessage?: string
  forceDesktopTableInMobile?: boolean
  emptyDataImageSource?: string
}) => {
  const { isMobile } = useLayout()
  const {
    columns,
    rows,
    onRowClick,
    checkboxSelection,
    onRowSelectionModelChange,
    rowSelectionModel,
  } = props

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: sortFieldName ?? 'name',
      sort: sort ?? 'asc',
    },
  ])

  if (isEmpty(rows)) {
    return (
      <NoDataIndicator
        noDataMessage={noDataMessage}
        imageSource={emptyDataImageSource}
      />
    )
  }

  if (isMobile && !forceDesktopTableInMobile) {
    return (
      <MobileDataTable
        columns={columns as Array<GridColDef<RowModelT>>}
        rows={rows}
        onRowClick={onRowClick}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
      />
    )
  }

  return (
    <DataGrid<RowModelT>
      sx={{
        '& .MuiDataGrid-row:hover': {
          backgroundColor: colorPrimitives.grayDataTableHover,
        },
        '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer':
          {
            display: 'none',
          },
        '& .MuiDataGrid-row, & .MuiDataGrid-cell:first-of-type': {
          color: colorPrimitives.black,
        },
      }}
      autoHeight
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick
      disableColumnMenu
      sortModel={sortModel}
      onSortModelChange={(model) => {
        setSortModel(model)
      }}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 25 },
        },
      }}
      hideFooter={props.rows.length <= 25}
      pageSizeOptions={[25, 50, 100]}
      {...props}
    />
  )
}
