import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

type LEConnectUserDetailPageTabsType = 'Overview'

export const LEConnectUserDetailPageTabs = ({
  currentTab,
  leuserId,
}: {
  currentTab: LEConnectUserDetailPageTabsType
  leuserId: number
}) => {
  return (
    <Tabs value={currentTab}>
      <Tab
        label="Overview"
        value="Overview"
        to={`/ClientManagement/LEConnectUsers/${leuserId}`}
        component={Link}
      />
    </Tabs>
  )
}
