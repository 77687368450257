import { CircularProgress } from '@mui/material'
import { DataTable } from 'components/Shared/DataTable'
import {
  type GridEventListener,
  type GridColDef,
  type GridRowParams,
} from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useGetRewardItems } from 'hooks/api/useGetRewardItems'
import { useState } from 'react'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import { type Reward } from 'types/api'

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Reward Item Name',
    minWidth: 150,
    flex: 1,
  },
  { field: 'id', headerName: 'Item ID', minWidth: 100, flex: 1 },
]

export const RewardItemsTable = () => {
  const navigate = useNavigate()
  const rewardItemsQuery = useGetRewardItems()
  const [filteredData, setFilteredData] = useState<Reward[]>()

  const handleFilteredData = (filteredData: Reward[]) => {
    setFilteredData(filteredData)
  }

  if (rewardItemsQuery.isPending) {
    return <CircularProgress />
  }

  if (rewardItemsQuery.isError) {
    return <p>An error occured.</p>
  }

  const selectRow: GridEventListener<'rowClick'> = (params: GridRowParams) => {
    navigate(`/Rewards/RewardItems/${params.id}`)
  }

  return (
    <>
      <GridSearchField<'FullRewardDTO'>
        placeholder="Search Reward Items"
        data={rewardItemsQuery.data as Reward[]}
        handleFilteredData={handleFilteredData}
        fullWidth
      />

      <DataTable
        data-testid="custom-data-table"
        columns={columns}
        rows={filteredData ?? (rewardItemsQuery.data as Reward[])}
        onRowClick={selectRow}
      />
    </>
  )
}
