import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { DataTable } from 'components/Shared/DataTable'
import { useGridApiRef } from '@mui/x-data-grid'
import {
  getAdminExportColumns,
  getAdminExportRows,
  LookupType,
} from 'utils/adminExportUtils'
import { useGetExportForWinnersByContestId } from 'hooks/api/Exports/useGetExportForWinnersByContestId'
import { grey } from '@mui/material/colors'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'

export const AdminExportModal = ({
  isModalOpen,
  toggleModal,
  header,
  lookupType,
  lookupId,
}: {
  isModalOpen: boolean
  toggleModal: () => void
  header: string
  lookupType: LookupType | undefined
  lookupId: string | undefined
}) => {
  let exportFileName
  let exportQuery

  switch (lookupType) {
    case LookupType.WinnersByContestId:
      exportFileName = 'ExportForWinners'
      exportQuery = useGetExportForWinnersByContestId({
        contestId: Number(lookupId),
      })
      break
    default:
      return
  }

  // Get columns from returned query
  const columns = getAdminExportColumns(exportQuery?.data)

  // Get rows from returned query
  const rows = getAdminExportRows(exportQuery?.data)

  const dataTableApiRef = useGridApiRef()
  const exportData = () => {
    if (rows.length > 0) {
      dataTableApiRef.current.exportDataAsCsv({
        fileName: exportFileName,
        utf8WithBom: true,
      })
      toggleModal()
    }
  }

  return (
    <Dialog open={isModalOpen} maxWidth="xl">
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }}>
        <Box px={4} py={4}>
          <Typography variant="h2" fontWeight={'bold'}>
            Export: {header}
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {`CSV file format will be exported with a filename of ${exportFileName}`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={exportData}>
          <Box>
            {exportQuery.isPending ? (
              <ActivityIndicator />
            ) : (
              <DataTable
                apiRef={dataTableApiRef}
                getRowId={(row) => row.exportKeyId ?? 0}
                columns={columns}
                rows={rows}
              />
            )}
          </Box>
        </form>
      </DialogContent>
      <DialogActions className="border-t">
        <Box className="flex flex-row justify-end p-4 gap-x-2">
          <Button variant="text" onClick={toggleModal}>
            Cancel
          </Button>
          <Button variant="contained" type="button" onClick={exportData}>
            Export
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
