import { useParams } from 'react-router-dom'
import { Page } from 'components/Shared/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { Box, Typography } from '@mui/material'
import { type components } from 'src/api/playerPayback/api'
import { RewardProgramDetailHeader } from '../RewardsProgramDetailsHeader/RewardProgramDetailHeader'
import { formatPhoneNumber } from '@jjvgaming/player-payback-library'
import { CellLink } from 'components/CellLink/CellLink'
import {
  type GridRenderCellParams,
  type GridColDef,
  type GridPaginationModel,
  type GridSortModel,
} from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { DataTablePaging } from 'components/Shared/DataTablePaging'
import { useLayout } from 'hooks/useLayout'
import { useGetPagedPatronBalanceSummaryByRewardsProgramId } from 'hooks/api/RewardProgram/useGetPagedPatronBalanceSummaryByRewardsProgramId'

const getColumns = (
  isMobile: boolean
): Array<GridColDef<components['schemas']['FullPointsLedgerSummaryDTO']>> => [
  {
    field: 'patronName',
    headerName: 'Name',
    sortingOrder: ['desc', 'asc'],
    valueGetter: (params) =>
      `${isMobile ? 'Name: ' : ''}${params.row.patron?.firstName} ${
        params.row.patron?.lastName
      }`,
    width: 500,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <CellLink
        to={`/PatronAccounts/${params.row.patronId as string}/Overview`}
        fontWeight={isMobile ? 'bold' : 'regular'}
        text={`${isMobile ? 'Name: ' : ''}${
          params.row.patron?.firstName as string
        } ${params.row.patron?.lastName as string}`}
      />
    ),
  },
  {
    field: 'patronId',
    headerName: 'ID',
    sortingOrder: ['desc', 'asc'],
    type: 'number',
    valueGetter: (params) => `${isMobile ? 'ID: ' : ''}${params.row.patronId}`,
    width: 150,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    sortingOrder: ['desc', 'asc'],
    valueGetter: (params) =>
      `${isMobile ? 'Phone Number: ' : ''}${formatPhoneNumber(
        params.row.patron?.phoneNumber
      )}`,
    width: 180,
    flex: 1,
  },
  {
    field: 'balance',
    headerName: 'Balance',
    sortingOrder: ['desc', 'asc'],
    type: 'number',
    valueGetter: (params: {
      row: {
        balance?: number
      }
    }) => `${isMobile ? 'Balance: ' : ''}${params.row.balance}`,
    width: 150,
  },
]

export const RewardProgramBalancesPage = () => {
  useEffect(() => {
    // set back to page 0 when user searches to make sure results show
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize })
  }, [])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'Patron.FirstName',
      sort: 'asc',
    },
  ])
  const { isMobile } = useLayout()
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortColumnName, setSortColumnName] = useState('Patron.FirstName')

  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById(Number(rewardProgramId))

  const pointsLedgersQuery = useGetPagedPatronBalanceSummaryByRewardsProgramId({
    rewardProgramId: Number(rewardProgramId),
    paginationModel,
    sortColumnName,
    sortOrder,
    enabled: !!rewardProgramQuery.data,
  })

  if (rewardProgramQuery.isPending || pointsLedgersQuery.isPending) {
    return <ActivityIndicator size="fullPage" />
  }

  if (rewardProgramQuery.isError || pointsLedgersQuery.isError) {
    return <p>An error occurred.</p>
  }

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
  }

  const onSortModelChange = (model: GridSortModel) => {
    if (model.length > 0 && model[0].sort && model[0].field) {
      // set back to page 0 when user changes sort
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize })
      if (model[0].field === 'patronName') {
        setSortColumnName('Patron.FirstName')
      } else if (model[0].field === 'patronId') {
        setSortColumnName('Patron.Id')
      } else if (model[0].field === 'phoneNumber') {
        setSortColumnName('Patron.PhoneNumber')
      } else if (model[0].field === 'balance') {
        setSortColumnName('Balance')
      } else {
        setSortColumnName('Patron.LastName')
      }
      setSortOrder(model[0].sort)
    }
    setSortModel(model)
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/Rewards/RewardPrograms/${Number(
            rewardProgramId
          )}/Balances`}
        />
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          rowGap: { xs: '8px', sm: '24px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: { xs: 'start', sm: 'space-between' },
            alignItems: { xs: 'start', sm: 'center' },
            rowGap: '8px',
          }}
        >
          <Box>
            <Typography variant={'h2'}>Patron Balances</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h6">
              Grand Total: {pointsLedgersQuery.data.sum}
            </Typography>
          </Box>
        </Box>
        <DataTablePaging
          columns={getColumns(isMobile)}
          rows={pointsLedgersQuery.data.results ?? []}
          noDataMessage="No patron balances."
          totalCount={pointsLedgersQuery.data.rowCount ?? 0}
          paginationModel={paginationModel}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          onPaginationModelChange={onPaginationModelChange}
          sortFieldName={sortColumnName}
        />
      </Box>
    </Page>
  )
}
