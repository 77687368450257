import { create } from 'zustand'

interface DropdownSweepstakes {
  dropdownSweepstakesValue: string
  setdropdownSweepstakesValue: (dropdownSweepstakesValue: string) => void
}

export const useDropdownSweepstakesStore = create<DropdownSweepstakes>(
  (set) => ({
    dropdownSweepstakesValue: '',
    setdropdownSweepstakesValue: (dropdownSweepstakesValue: any) => {
      set({ dropdownSweepstakesValue })
    },
  })
)
