import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation } from '@tanstack/react-query'
import { type PatronSweepstakeEntryManual } from 'types/api'

interface usePostManualPatronSweepstakeEntryProps {
  onSuccess?: (
    data: PatronSweepstakeEntryManual,
    variables: PatronSweepstakeEntryManual
  ) => Promise<void>
  onError?: (error: Error, variables: PatronSweepstakeEntryManual) => void
}

export const usePostManualPatronSweepstakeEntry = ({
  onSuccess,
  onError,
}: usePostManualPatronSweepstakeEntryProps) => {
  const post = useTypedApiClient({
    path: '/admin/corporate-accounts/licensed-establishments/sweepstakeentry/manual',
    method: 'post',
  })

  return useMutation({
    mutationFn: async (manualSweepstakeEntry: PatronSweepstakeEntryManual) => {
      const { data } = await post(manualSweepstakeEntry)
      return data
    },
    onSuccess: async (data, variables) => {
      await onSuccess?.(data, variables)
    },
    onError,
  })
}
