import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetPatronById = (id: number) => {
  const get = useTypedApiClient({
    path: '/admin/patrons/{id}',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/admin/patronaccount/detail', id],
    queryFn: async () => {
      const { data } = await get({ id })
      return data
    },
  })
}
