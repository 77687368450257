import type { GridColDef } from '@mui/x-data-grid'
import { useRewardOptionsSize } from 'hooks/useRewardOptionsSize'
import type { RewardsRedemption } from 'types/api'
import { formatFullDateTime } from 'utils/util'

export const RewardsRedemptionsCSVColumns = (): GridColDef[] => [
  {
    field: 'recipientFirstName',
    headerName: 'Recipient First Name',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.patron?.firstName
    },
  },
  {
    field: 'recipientLastName',
    headerName: 'Recipient Last Name',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.patron?.lastName
    },
  },
  {
    field: 'birthday',
    headerName: 'Birthday',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.patron?.dob
    },
  },
  {
    field: 'item',
    headerName: 'Item',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.rewardsCatalogReward?.reward?.name
    },
  },
  {
    field: 'recipientPhone',
    headerName: 'Recipient Phone',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.patron?.phoneNumber
    },
  },
  {
    field: 'address1',
    headerName: 'Address Line 1',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.address?.address1
    },
  },
  {
    field: 'address2',
    headerName: 'Address Line 2',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.address?.address2
    },
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.address?.city
    },
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.address?.state
    },
  },
  {
    field: 'postalCode',
    headerName: 'Postal Code',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.address?.postalCode
    },
  },
  {
    field: 'itemSku',
    headerName: 'Item SKU',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.rewardsCatalogReward?.reward?.sku
    },
  },
  {
    field: 'itemName',
    headerName: 'Item Name',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.rewardsCatalogReward?.reward?.vendorItemName
    },
  },
  {
    field: 'itemOptions',
    headerName: 'Item Options',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.options?.value
        ? `${String(useRewardOptionsSize(params.row.options?.value))}`
        : ''
    },
  },
  {
    field: 'transactionDate',
    headerName: 'Transaction Date',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return formatFullDateTime(params.row.transactionDate)
    },
  },
  {
    field: 'licensedEstablishment.standardName',
    headerName: 'Location Name',
    flex: 1,
    valueGetter: (params: { row: RewardsRedemption }) => {
      return params.row.licensedEstablishment?.standardName
    },
  },
]
