import { object, string } from 'yup'
import { useSnackbar } from 'stores/useSnackbar'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { ActivityButton } from 'components/ActivityButton'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { usePutLicensedEstablishmentUser } from 'hooks/api/LicensedEstablishmentUser/usePutLicensedEstablishmentUser'
import { useGetLicensedEstablishmentUserById } from 'hooks/api/LicensedEstablishmentUser/useGetLicensedEstablishmentUserById'
import { grey } from '@mui/material/colors'

const LEUserSchema = object({
  LEUser: object({
    firstName: string().required('First Name is a required field'),
    lastName: string().required('Last Name is a required field'),
    email: string()
      .email('Invalid email')
      .required('Email is a required field'),
  }),
})

export const EditLEUserInformation = ({
  LEUserId,
  isModalOpen,
  toggleModal,
}: {
  LEUserId: number
  isModalOpen: boolean
  toggleModal: () => void
}) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const licensedEstablishmentUserQuery = useGetLicensedEstablishmentUserById({
    licensedEstablishmentUserId: LEUserId,
  })
  const formMethods = useForm({
    resolver: yupResolver(LEUserSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const putLicensedEstablishmentUserMutation = usePutLicensedEstablishmentUser({
    licensedEstablishmentUserId: LEUserId,
    onSuccess: async () => {
      setMessage('User has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      toggleModal()
    },
    onError: async () => {
      setMessage('Error updating J&J Connect User', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const handleSubmitWrapper = handleSubmit((data) => {
    const { firstName, lastName, email } = data.LEUser

    putLicensedEstablishmentUserMutation.mutate({
      ...leUser,
      firstName,
      lastName,
      email,
      type: leUser.licensedEstablishments?.[0].type ?? 'Staff',
    })
  })

  if (licensedEstablishmentUserQuery.isPending) {
    return <ActivityIndicator />
  }

  if (licensedEstablishmentUserQuery.isError) {
    return <p>An error occurred.</p>
  }

  const leUser = licensedEstablishmentUserQuery.data

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '800px' } }}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Edit Contact Information
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {`J&J Connect User: ${leUser?.firstName} ${leUser?.lastName}`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmitWrapper} noValidate className="p-5">
            {errors.LEUser && (
              <Grid item xs={12} pb={4}>
                <Alert severity="error">
                  Please correct errors to continue
                </Alert>
              </Grid>
            )}

            <Grid container>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="LEUser.firstName"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  First Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LEUser.firstName', { required: true })}
                  defaultValue={leUser?.firstName}
                  error={isPresent(errors.LEUser?.firstName)}
                />
                {errors.LEUser?.firstName?.message && (
                  <FormFieldErrorMessage
                    message={errors.LEUser.firstName.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="LEUser.lastName"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Last Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LEUser.lastName', { required: true })}
                  defaultValue={leUser?.lastName}
                  error={isPresent(errors.LEUser?.lastName)}
                />
                {errors.LEUser?.lastName?.message && (
                  <FormFieldErrorMessage
                    message={errors.LEUser.lastName.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="LEUser.email"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Email
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LEUser.email', { required: true })}
                  defaultValue={leUser?.email}
                  error={isPresent(errors.LEUser?.email)}
                />
                {errors.LEUser?.email?.message && (
                  <FormFieldErrorMessage
                    message={errors.LEUser.email.message}
                  />
                )}
              </Grid>
            </Grid>
            <DialogActions className="border-t">
              <Button variant="text" onClick={toggleModal}>
                Cancel
              </Button>
              <ActivityButton
                active={putLicensedEstablishmentUserMutation.isPending}
                type="submit"
                disabled={!!errors.LEUser}
                variant="contained"
              >
                Save
              </ActivityButton>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
