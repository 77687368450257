import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { type components } from 'api/playerPayback/api'
import { useGetNumberLocationPrizeEntries } from 'hooks/api/Sweepstake/EntriesCount/useGetNumberLocationPrizeEntries'

export const LocationPrizeDetails = ({
  licensedEstablishment,
  sweepstakeId,
}: {
  licensedEstablishment: components['schemas']['FullLicensedEstablishmentDTO']
  sweepstakeId: number
}) => {
  const theme = useTheme()

  const totalEntries = useGetNumberLocationPrizeEntries({
    contestId: sweepstakeId,
    licensedEstablishmentId: licensedEstablishment.id,
  })

  if (totalEntries.isPending) {
    return (
      <Box>
        <CircularProgress />
        <Typography> Loading getNumberLocationPrizeEntries query...</Typography>
      </Box>
    )
  }

  const { address1, address2, city, state, postalCode } =
    licensedEstablishment?.addresses?.[0] ?? {}

  return (
    <>
      <Typography variant="h2" pb={2}>
        Location Details
      </Typography>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              LE Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment?.standardName}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              License Number
            </Typography>
            <Typography variant="body1" pb={2}>
              #{licensedEstablishment?.licenseNumber}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              LE Address
            </Typography>
            <Typography variant="body1" pb={2}>
              {address1 ?? '-'}
              {address2 && (
                <>
                  <br />
                  {address2}
                </>
              )}
              {(city ?? state ?? postalCode) && (
                <>
                  <br />
                  {city ? `${city}, ` : ''}
                  {state ? `${state}, ` : ''}
                  {postalCode ?? ''}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Corporate Account
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment?.corporateAccount?.name ?? '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Organization
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment?.organization?.name ?? '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Total Entries for Location
            </Typography>
            {totalEntries.data !== undefined ? (
              <Typography variant="body1" pb={2}>
                {String(totalEntries.data)}
              </Typography>
            ) : (
              <Typography variant="body1" pb={2}>
                Could not retrieve
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
