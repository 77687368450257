import { type components } from 'api/playerPayback/api'
import { bool, object, string } from 'yup'

export type LeUserRoleType =
  components['schemas']['LicensedEstablishmentUserRoleType']

export const LeUserRoles: Array<LeUserRoleType | undefined> = [
  'Owner',
  'Staff',
  'SalesAccountManager',
  'LimitedLiveAtLocation',
  'SalesServiceRep',
]

export const LEUserRoleAndPermissionSchema = object({
  type: string()
    .oneOf(LeUserRoles)
    .nullable()
    .test((value) => value !== undefined && value !== null),
  reportsFinancialPermission: bool().required(),
  reportsNonFinancialPermission: bool().required(),
  reportsAccessToPIIPermission: bool().required(),
  reportsDownloadPIIPermission: bool().required(),
  patronsAccountsPermission: bool().required(),
  rewardsPermission: bool().required(),
  promotionsPermission: bool().required(),
  locationsPermission: bool().required(),
  leUsersPermission: bool().required(),
  liveAtLocationViewPermission: bool().required(),
  sweepstakesPermission: bool().required(),
  allPermissions: bool(),
  allReports: bool(),
})
