import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'
import {
  type SweepstakeResultType,
  type SweepstakeWinnerStatusType,
  type SweepstakeWinner,
} from 'src/types/api'

interface UsePutSweepstakeWinnerProps
  extends UseMutationOptions<SweepstakeWinner, ApiError, RequestFields> {
  onSuccess?: (data: SweepstakeWinner) => void
  onError?: (error: ApiError) => void
}

export interface RequestFields {
  sweepstakeDrawingId: number
  patronId: number
  contestEntryId: number
  redrawReason: string | null | undefined
  resultNote: string | null | undefined
  validatedBy: string | null | undefined
  validatedOn: string | null | undefined
  dateDrawn: string | null | undefined
  locationValidationStartDate: string | null | undefined
  affidavitCompletedAt: string | null | undefined
  dateRedrawnAt: string | null | undefined
  resultType: SweepstakeResultType | undefined
  id: number
  status: SweepstakeWinnerStatusType | undefined
  drawingSequence: number | undefined
  notes: string | null | undefined
}

export const usePutSweepstakeWinner = ({
  onSuccess,
  onError,
}: UsePutSweepstakeWinnerProps) => {
  const queryClient = useQueryClient()
  const putSweepstakeWinner = useTypedApiClient({
    path: '/admin/sweepstake-winners/{id}',
    method: 'put',
  })

  return useMutation<SweepstakeWinner, ApiError, RequestFields>({
    mutationFn: async (sweepstakeWinner) => {
      const { data: putSweepstakeWinnerResult } = await putSweepstakeWinner({
        id: sweepstakeWinner.id,
        contestEntryId: sweepstakeWinner.contestEntryId,
        patronId: sweepstakeWinner.patronId,
        status: sweepstakeWinner.status,
        sweepstakeDrawingId: sweepstakeWinner.sweepstakeDrawingId,
        drawingSequence: sweepstakeWinner.drawingSequence,
        resultType: sweepstakeWinner.resultType,
        resultNote: sweepstakeWinner.resultNote,
        validatedBy: sweepstakeWinner.validatedBy,
        validatedOn: sweepstakeWinner.validatedOn,
        redrawReason: sweepstakeWinner.redrawReason,
        dateDrawn: sweepstakeWinner.dateDrawn,
        locationValidationStartDate:
          sweepstakeWinner.locationValidationStartDate,
        affidavitCompletedAt: sweepstakeWinner.affidavitCompletedAt,
        dateRedrawnAt: sweepstakeWinner.dateRedrawnAt,
        notes: sweepstakeWinner.notes,
      })
      return putSweepstakeWinnerResult
    },
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data)
      }
      await queryClient.invalidateQueries({
        queryKey: ['/sweepstake-winners', data.id],
      })
    },
    onError,
  })
}
