import { formatDateTime } from 'utils/util'
import { useParams } from 'react-router-dom'
import { Page } from 'components/Shared/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { Box, Button, MenuItem, Typography, Stack } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useGetPointsLedgersByRewardProgramId } from 'hooks/api/useGetPointsLedgersByRewardProgramId'
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridPaginationModel,
  type GridSortModel,
} from '@mui/x-data-grid'
import { useDebounce } from '@uidotdev/usehooks'
import { SearchField } from 'components/SearchField'
import { type components } from 'src/api/playerPayback/api'
import { DataTablePaging } from 'components/Shared/DataTablePaging'
import { useLayout } from 'hooks/useLayout'
import { CellLink } from 'components/CellLink/CellLink'
import { RewardProgramDetailHeader } from '../RewardsProgramDetailsHeader/RewardProgramDetailHeader'
import { useState, useEffect } from 'react'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'

const getColumns = (
  isMobile: boolean
): Array<GridColDef<components['schemas']['FullPointsLedgerEntryDTO']>> => [
  {
    field: 'licensedEstablishmentName',
    headerName: 'LE Name',
    valueGetter: (params) =>
      params.row.patronCheckIn?.licensedEstablishment?.standardName,
    width: 320,
    renderCell: (params: GridRenderCellParams) => (
      <CellLink
        to={`/LicensedEstablishments/${
          params.row.patronCheckIn?.licensedEstablishment?.id as string
        }`}
        fontWeight={isMobile ? 'bold' : 'regular'}
        text={
          params.row.patronCheckIn?.licensedEstablishment?.standardName ?? '-'
        }
      />
    ),
  },
  {
    field: 'licensedEstablishmentId',
    headerName: 'LE ID',
    valueGetter: (params) =>
      `${isMobile ? 'LE ID: ' : ''}${
        params.row.patronCheckIn?.licensedEstablishment?.id ?? '-'
      }`,
    width: 222,
  },
  {
    field: 'patronName',
    headerName: 'Patron Name',
    valueGetter: (params) =>
      `${isMobile ? 'Patron Name: ' : ''}${
        params.row.patron?.firstName
      } ${params.row.patron?.lastName}`,
    width: 320,
  },
  {
    field: 'patronId',
    headerName: 'Patron ID',
    valueGetter: (params) =>
      `${isMobile ? 'Patron ID: ' : ''}${params.row.patronId}`,
    width: 222,
  },
  {
    field: 'amount',
    headerName: 'Points Awarded',
    valueGetter: (params: {
      row: {
        amount?: number
      }
    }) => `${isMobile ? 'Points Awarded: ' : ''}${params.row.amount}`,
    width: 222,
  },
  {
    field: 'createdBy',
    headerName: 'Manually Awarded By',
    valueGetter: (params) => {
      const createdBy = params.row.createdBy
      return createdBy === 'JJVentures.PlayerPAYBACK.WebApi' ? '' : createdBy
    },
    width: 260,
  },
  {
    field: 'createdOn',
    headerName: 'Transaction Timestamp',
    valueFormatter: (params: { value: string }) => {
      const date = formatDateTime(params.value, 'MM-dd-yyyy p')
      return isMobile ? `Date: ${String(date)}` : date
    },
    width: 260,
  },
]

export const RewardProgramTransactionHistoryPage = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [numDays, setNumDays] = useState<number>(30)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'createdOn',
      sort: 'desc',
    },
  ])
  const [query, setQuery] = useState('')
  const [sortOrder, setSortOrder] = useState('desc')
  const [sortColumnName, setSortColumnName] = useState('CreatedOn')
  const [searchTerm, setSearchTerm] = useState('')
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById(Number(rewardProgramId))
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    // set back to page 0 when user searches to make sure results show
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize })
    setSearchTerm(debouncedQuery)
  }, [debouncedQuery])

  const { isMobile } = useLayout()
  const pointsLedgersQuery = useGetPointsLedgersByRewardProgramId({
    rewardProgramId: Number(rewardProgramId),
    currentPage: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    numOfDays: numDays,
    searchTerm,
    sortColumnName,
    sortOrder,
    enabled: !!rewardProgramQuery.data,
  })
  const open = Boolean(anchorEl)
  const handleMenuItemClose = (val: number) => {
    // set back to page 0 when user changes number of days included
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize })
    setNumDays(val)
    setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
  }

  const onSortModelChange = (model: GridSortModel) => {
    if (model.length > 0 && model[0].sort && model[0].field) {
      // set back to page 0 when user changes sort
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize })
      if (model[0].field === 'patronName') {
        setSortColumnName('Patron.FirstName')
      } else if (model[0].field === 'licensedEstablishmentName') {
        setSortColumnName('PatronCheckIn.LicensedEstablishment.StandardName')
      } else if (model[0].field === 'licensedEstablishmentId') {
        setSortColumnName('PatronCheckIn.LicensedEstablishmentId')
      } else if (model[0].field === 'patronId') {
        setSortColumnName('Patron.Id')
      } else if (model[0].field === 'amount') {
        setSortColumnName('Amount')
      } else if (model[0].field === 'createdBy') {
        setSortColumnName('CreatedBy')
      } else {
        setSortColumnName('CreatedOn')
      }
      setSortOrder(model[0].sort)
    }
    setSortModel(model)
  }

  if (rewardProgramQuery.isPending) {
    return <ActivityIndicator size="fullPage" />
  }

  if (rewardProgramQuery.isError || pointsLedgersQuery.isError) {
    return <p>An error occurred.</p>
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/Rewards/RewardPrograms/${Number(
            rewardProgramId
          )}/TransactionHistory`}
        />
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          rowGap: { xs: '8px', sm: '24px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: { xs: 'start', sm: 'space-between' },
            alignItems: { xs: 'start', sm: 'center' },
            rowGap: '8px',
          }}
        >
          <Box>
            <Typography variant={'h2'}>Transaction History</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              id="activity-day-button"
              sx={{ display: 'flex', flexDirection: 'row', pr: 0 }}
              onClick={handleMenuClick}
            >
              <Typography variant="body-1">Last {numDays} days</Typography>
              {open ? (
                <KeyboardArrowUpIcon
                  sx={{
                    width: '22px',
                    ml: '4px',
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{
                    width: '22px',
                    ml: '4px',
                  }}
                />
              )}
            </Button>
            <StyledMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                '& .MuiPaper-root': {
                  marginTop: 0,
                  minWidth: 340,
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClose(30)}>
                30 Days
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClose(90)}>
                90 Days
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClose(365)}>
                365 Days
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>
        <Stack>
          <SearchField
            placeholder="Search Transaction History"
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
          />
        </Stack>
        {pointsLedgersQuery.isPending ? (
          <ActivityIndicator size="fullPage" />
        ) : (
          <DataTablePaging
            columns={getColumns(isMobile)}
            rows={pointsLedgersQuery.data.results ?? []}
            totalCount={pointsLedgersQuery.data.rowCount ?? 0}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            onSortModelChange={onSortModelChange}
            sortModel={sortModel}
            noDataMessage="No transaction history to date."
          />
        )}
      </Box>
    </Page>
  )
}
