export const white = '#ffffff'

export const colorPrimitives = {
  grayDataTableHover: '#f7f7f6',
  gray70: '#70706c',
  lightGray: '#f7f7f6',
  borderGray: '#292b261a',
  darkGray: '#2C2C27AD',
  redGaming: '#CC2027',
  venturesRed: '#722626',
  warmGray1: '#D6D1CA',
  warnGray5: '#AEA99D',
  pms2223: '#7A7167',
  black: '#2C2C27',
  black2: '#1E4620',
  goldenRod: '#F0D283',
  feltBlue: '#1A4F64',
  white,
  errorRed: '#CC2027',
}

export const Colors = {
  sweepstakesStatus: {
    background: {
      Active: '#C1EBC2',
      Pending: '#F9D578',
      Draft: 'rgb(235, 235, 235)',
      Enrollable: 'rgb(243, 214, 133)',
      EnrollmentClosed: 'rgb(235, 235, 235)',
      Promoted: 'rgb(243, 214, 133)',
      Live: 'rgb(201, 233, 197)',
      InternalValidation: 'rgb(245, 229, 229)',
      WinnerValidation: 'rgb(243, 214, 133)',
      Closed: 'rgb(235, 235, 235)',
    },
    text: {
      Active: '#1B5E20',
      Pending: '#663C00',
      Draft: 'rgb(35, 35, 35)',
      Enrollable: 'rgb(96, 62, 17)',
      EnrollmentClosed: 'rgb(35, 35, 35)',
      Promoted: 'rgb(96, 62, 17)',
      Live: 'rgb(42, 82, 35)',
      InternalValidation: 'rgb(187, 51, 48)',
      WinnerValidation: 'rgb(96, 62, 17)',
      Closed: 'rgb(35, 35, 35)',
    },
  },
  sweepstakesWinnerStatus: {
    background: {
      LocationValidation: colorPrimitives.borderGray,
      RedrawRequest: '#CC20271F',
      WinnerValidation: '#F9D578',
      WinnerNotified: colorPrimitives.borderGray,
      AffidavitReceived: '#09749E1F',
      Awarded: colorPrimitives.borderGray,
      PrizeClaim: colorPrimitives.borderGray,
      NoWinner: colorPrimitives.borderGray,
      GrandPrizeValidation: colorPrimitives.borderGray,
    },
    text: {
      LocationValidation: 'black',
      RedrawRequest: '#CC2027',
      WinnerValidation: '#663C00',
      WinnerNotified: 'black',
      AffidavitReceived: '#1A4F64',
      Awarded: 'black',
      PrizeClaim: 'black',
      NoWinner: 'black',
      GrandPrizeValidation: 'black',
    },
  },
  promotionsStatus: {
    background: {
      Active: '#C1EBC2',
      Scheduled: '#bbdffc',
      Expired: '#ebebeb',
    },
  },
  campaignMessageStatus: {
    background: {
      Scheduled: '#F9D578',
      Sent: '#09749E1F',
    },
    text: {
      Scheduled: '#663C00',
      Sent: '#1A4F64',
    },
  },
  licensedEstablishmentStatus: {
    background: {
      active: '#C1EBC2',
      inactive: '#E0E0E0',
    },
    text: {
      active: '#18541C',
      inactive: '#2C2C27',
    },
  },
  muiLabels: {
    placeholder: '#292B2699',
  },
  backgroundGray: '#fafafa',
}
