import { create } from 'zustand'

interface DropdownParticipatingLocations {
  dropdownParticipatingLocationsValue: string
  setdropdownParticipatingLocationsValue: (
    dropdownParticipatingLocationsValue: string
  ) => void
}

export const useDropdownParticipatingLocationsStore =
  create<DropdownParticipatingLocations>((set) => ({
    dropdownParticipatingLocationsValue: '',
    setdropdownParticipatingLocationsValue: (
      dropdownParticipatingLocationsValue: any
    ) => {
      set({ dropdownParticipatingLocationsValue })
    },
  }))
