import { isNil } from 'lodash'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'stores/useSnackbar'
import { Box, Button, Grid, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { type components } from 'src/api/playerPayback/api'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { CheckBoxList } from 'components/List/CheckBoxList'
import { useRewardsCatalog } from 'hooks/api/useRewardsCatalog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApiError, arrayRequestBody } from 'openapi-typescript-fetch'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'

export const AssignCatalogToRewardProgram = ({
  rewardProgramId,
  toggleIsOpen,
}: {
  rewardProgramId: number
  toggleIsOpen: () => void
}) => {
  const queryClient = useQueryClient()
  const setMessage = useSnackbar((state) => state.setMessage)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

  const { handleSubmit } = useForm({})

  const {
    isPending: isPendingRewardCatalog,
    isError: isErrorRewardCatalog,
    data: rewardCatalogs,
  } = useRewardsCatalog({ enabled: true })

  const {
    isPending: isPendingRewardProgram,
    data: rewardProgram,
    isError: isErrorRewardProgram,
  } = useGetRewardProgramById(Number(rewardProgramId))

  const selectedCatalogs = rewardProgram?.rewardsCatalogs?.map((catalog) => {
    return Number(catalog.id)
  })

  const [checkedCatalogs, setCheckedCatalogs] = useState<number[]>(
    selectedCatalogs ?? []
  )

  const handleToggle = (catalogId: number) => () => {
    const prevCheckedCatalogs = (prevCheckedCatalogs: any[]) => {
      if (prevCheckedCatalogs.includes(catalogId)) {
        return prevCheckedCatalogs.filter((id: number) => id !== catalogId)
      } else {
        return [...prevCheckedCatalogs, catalogId]
      }
    }
    setCheckedCatalogs(prevCheckedCatalogs)
  }

  const enableSubmitButton =
    checkedCatalogs.length === 0 && (selectedCatalogs ?? []).length === 0

  const patch = useTypedApiClient({
    path: '/admin/rewards-programs/{id}/rewards-catalogs',
    method: 'patch',
  })

  const assignCatalog = useMutation<unknown, Error>({
    mutationFn: async () => {
      return await patch(
        arrayRequestBody(checkedCatalogs, { id: rewardProgramId })
      )
    },

    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-programs', rewardProgramId],
      })
      await queryClient.invalidateQueries({
        queryKey: ['admin-rewards-program-catalogs-by-rp-id'],
      })

      setLoadingSubmit(false)
      toggleIsOpen()

      const data = response.data as Array<
        components['schemas']['FullRewardsProgramCatalogDTO']
      >

      if (data.filter((x) => x.id === 0).length > 0) {
        setMessage(
          `The following catalogs were removed from the Rewards Program: ${data
            .map((x) => x.rewardsCatalog?.name)
            .join(',')}`,
          'success',
          {
            vertical: 'top',
            horizontal: 'right',
          }
        )
        return
      }

      setMessage(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${response.data.length} Catalog(s) assigned to ${rewardProgram?.name}`,
        'success',
        {
          vertical: 'top',
          horizontal: 'right',
        }
      )
    },
    onError: (error: Error) => {
      setLoadingSubmit(false)
      if (error instanceof ApiError) {
        setMessage(error.data.message, 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
        return
      }

      setMessage(error.message, 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const submitAssignCatalog = handleSubmit(() => {
    assignCatalog.mutate()
    setLoadingSubmit(true)
  })

  const actions = (
    <form onSubmit={submitAssignCatalog}>
      <Box className="flex flex-row w-full space-y-1 sm:space-y-1 space-x-1 justify-end">
        <Button
          variant="text"
          type="button"
          onClick={() => {
            toggleIsOpen()
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loadingSubmit}
          loadingPosition="start"
          variant="contained"
          startIcon={<></>}
          className="!m-0"
          disabled={enableSubmitButton}
          type="submit"
        >
          Submit
        </LoadingButton>
      </Box>
    </form>
  )

  if (
    isPendingRewardProgram ||
    isNil(rewardProgram) ||
    isNil(rewardProgramId) ||
    isPendingRewardCatalog
  ) {
    return <ActivityIndicator />
  }

  if (isErrorRewardCatalog || isErrorRewardProgram) {
    return <p>An error occurred.</p>
  }

  return (
    <Box padding={6} className={`h-[100%] overflow-hidden`}>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        {`Assign a Reward Catalog to ${rewardProgram.name}`}
      </Typography>
      <Box className={`h-[100%] overflow-auto`}>
        <Grid className="contents h-full flex flex-col justify-between">
          <Grid
            paddingY={4}
            item
            xs={12}
            sm={12}
            className="flex-initial h-full max-h-[90%]"
          >
            <div className="h-full">
              <CheckBoxList<'FullRewardsCatalogDTO'>
                items={
                  rewardProgram.type === 'Global'
                    ? rewardCatalogs.filter(
                        (catalog) => catalog.type !== 'LocationCatalog'
                      )
                    : rewardCatalogs.filter(
                        (catalog) => catalog.type !== 'JJStoreCatalog'
                      )
                }
                propertyShow={'name'}
                identifier={'id'}
                handleToggle={handleToggle}
                checked={checkedCatalogs}
                titleForSelected="catalogs selected"
                searchable
                searchablePlaceHolder="Search existing catalogs"
              />
            </div>
          </Grid>
          <Grid paddingY={4} className="flex-auto !p-0">
            {actions}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
