import { Box, Button, Typography } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { Link as RouterLink } from 'react-router-dom'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetPassportGames } from 'hooks/api/useGetPassportGames'
import { DataTable } from 'components/Shared/DataTable'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { type GridColDef } from '@mui/x-data-grid'
import { formatFullDate } from 'utils/util'
import { Colors } from 'components/Theme'

interface ContestType {
  contestType: 'PassportGame' | 'Sweepstake'
}

const getStatusColor = (status: any) => {
  switch (status) {
    case 'Active':
      return Colors.sweepstakesStatus.background.Active
    case 'Pending':
      return Colors.sweepstakesStatus.background.Pending
    case 'Closed':
      return '#E0E0E0'
    default:
      return 'white'
  }
}

const getStatusTextColor = (status: any) => {
  switch (status) {
    case 'Active':
      return '#18541c'
    case 'Pending':
      return '#5E3700'
    case 'Closed':
      return '#2c2c27'
    default:
      return 'inherit'
  }
}

export const PassportGamesPage = () => {
  const query = useGetPassportGames()
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Game Name',
      renderCell: (params) => <Typography>{params.row.name}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: getStatusColor(params.row.status),
            padding: '8px 8px 8px 8px',
            borderRadius: '4px',
            fontWeight: 'bold',
            color: getStatusTextColor(params.row.status),
            textTransform: 'uppercase',
          }}
        >
          {params.value === 'Closed' ? 'Expired' : params.value}
        </Box>
      ),
    },
    {
      field: 'startDate',
      headerName: 'Start',
      minWidth: 150,
      renderCell: (params) => <Box>{formatFullDate(params.value)}</Box>,
    },
    {
      field: 'endDate',
      headerName: 'End',
      minWidth: 150,
      renderCell: (params) => <Box>{formatFullDate(params.value)}</Box>,
    },
  ]

  return (
    <Page
      header={
        <PageHeader
          title="Passport Games"
          eyebrow="CONTESTS"
          actionButton={
            <RouterLink to={'/Contests/PassportGames/New'} className="gap-1">
              <Button
                variant="contained"
                sx={{
                  px: '16px',
                  width: { xs: '100%', sm: '100%', md: 220, lg: 220 },
                }}
              >
                New Passport Game
              </Button>
            </RouterLink>
          }
        />
      }
    >
      {query.isPending && <ActivityIndicator />}
      {query.isError && <p>An error occured.</p>}
      {!query.isError && query.data && (
        <DataTable
          columns={columns}
          rows={query.data as unknown as ContestType[]}
        />
      )}
    </Page>
  )
}
