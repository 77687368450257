import { Box, Button, Grid, Typography } from '@mui/material'
import { CorporateAccountsTreeDataGrid } from 'components/CorporateAccountsTreeDataGrid/CorporateAccountsTreeDataGrid'
import { NoDataIndicator } from 'components/NoDataIndicator/NoDataIndicator'
import { usePutAccessLevels } from 'hooks/api/usePutAccessLevels'
import { type useUserData } from 'hooks/api/useUserData'
import { compact, includes, isNil } from 'lodash'
import { useState, useEffect } from 'react'
import { useSnackbar } from 'stores/useSnackbar'
import { transformSelectedLocations } from 'utils/util'

export const AccessLevels = ({
  corporateAccounts,
  user,
  organizations,
  licensedEstablishments,
}: {
  corporateAccounts: ReturnType<typeof useUserData>['corporateAccounts']
  user: ReturnType<typeof useUserData>['user']
  organizations: ReturnType<typeof useUserData>['organizations']
  licensedEstablishments: ReturnType<
    typeof useUserData
  >['licensedEstablishments']
}) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const initialSelectedValues = [
    ...(user?.licensedEstablishments ?? []).map(
      (item) => `licensedEstablishment-${item.licensedEstablishmentId}`
    ),
    ...(user?.organizations ?? []).map(
      (item) => `organization-${item.organizationId}`
    ),
    ...(user?.licensedEstablishmentUserCorporateAccounts ?? [])
      .filter((item) => item.allowed)
      .map((item) => `corporateAccount-${item.corporateAccountId}`),
  ]
  const [selectedLocations, setSelectedLocations] = useState<string[]>(
    initialSelectedValues ?? []
  )
  const [prevSelectedLocations, setPrevSelectedLocations] = useState<string[]>(
    initialSelectedValues ?? []
  )
  const [editModeIndex, setEditModeIndex] = useState<number | null>(null)
  const [justClickedEdit, setJustClickedEdit] = useState<boolean>(false)

  useEffect(() => {
    setSelectedLocations(initialSelectedValues ?? [])
  }, [
    user?.licensedEstablishments,
    user?.organizations,
    user?.licensedEstablishmentUserCorporateAccounts,
  ])

  useEffect(() => {
    setPrevSelectedLocations(initialSelectedValues ?? [])
  }, [
    user?.licensedEstablishments,
    user?.organizations,
    user?.licensedEstablishmentUserCorporateAccounts,
  ])

  const accessLevels = usePutAccessLevels({
    onSuccess: () => {
      setMessage('Access levels successfully updated', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const handleOnChange = (newSelection: any) => {
    if (justClickedEdit) {
      setJustClickedEdit(false)
    } else {
      setSelectedLocations(newSelection)
    }
  }
  const handleSubmit = () => {
    if (isNil(user)) {
      throw new Error('User is undefined')
    }
    const transformedData = transformSelectedLocations(selectedLocations)
    accessLevels.mutate({
      userId: user.id,
      ...transformedData,
    })

    setPrevSelectedLocations(selectedLocations)
    setEditModeIndex(null)
  }

  const handleCancel = () => {
    setSelectedLocations(prevSelectedLocations)
    setEditModeIndex(null)
  }

  if (
    licensedEstablishments.length === 0 &&
    corporateAccounts.length === 0 &&
    organizations.length === 0
  ) {
    return (
      <NoDataIndicator noDataMessage="No associated accounts for J&J Connect User" />
    )
  }

  return corporateAccounts.map((corporateAccount, index) => (
    <Box key={corporateAccount.id}>
      <Box className="flex flex-row items-center justify-between w-full mb-5">
        <Typography variant="h4">
          {corporateAccount?.name ??
            `Corporate Account #${corporateAccount?.id}`}
        </Typography>
        {editModeIndex !== index && (
          <Button
            onClick={() => {
              setEditModeIndex(index)
              setJustClickedEdit(true)
            }}
            variant="text"
          >
            Edit Access
          </Button>
        )}
      </Box>
      {corporateAccount && editModeIndex !== index && (
        <CorporateAccountsTreeDataGrid
          corporateAccounts={compact([corporateAccount])}
          organizations={compact(organizations[index])}
          licensedEstablishments={compact(licensedEstablishments[index])}
          initialExpanded={true}
          editing={false}
          showPermissionsEdit={true}
          user={user}
          allowedLocations={selectedLocations}
        />
      )}
      {corporateAccount && editModeIndex === index && (
        <CorporateAccountsTreeDataGrid
          corporateAccounts={compact([corporateAccount])}
          organizations={compact(organizations[index])}
          licensedEstablishments={compact(licensedEstablishments[index])}
          initialExpanded={true}
          editing={true}
          showPermissionsEdit={true}
          user={user}
          checkboxSelection
          rowSelectionModel={selectedLocations}
          onRowSelectionModelChange={handleOnChange}
          getRowClassName={(params) => {
            const id = params.id
            if (includes(selectedLocations, id)) {
              return 'bg-red'
            }
            return ''
          }}
        />
      )}
      {editModeIndex === index && (
        <Grid container gap={2} mt={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item></Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      )}
    </Box>
  ))
}
