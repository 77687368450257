import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'
import { type LicensedEstablishmentUser } from 'src/types/api'
import { useApiMutation } from 'hooks/useApiMutation'
import { useJJConnectUserSchema } from 'pages/ClientManagement/LEConnectUsersPage/useJJConnectUserSchema'

interface usePostJJConnectUserProps {
  onSuccess?: (data: LicensedEstablishmentUser) => void | Promise<void>
  onError?: (error: ApiError) => void
}

export const usePostJJConnectUser = ({
  onSuccess,
  onError,
}: usePostJJConnectUserProps) => {
  const post = useTypedApiClient({
    path: '/admin/licensed-establishments/users',
    method: 'post',
  })
  const { schema } = useJJConnectUserSchema()

  return useApiMutation<typeof post, typeof schema>({
    mutationFn: async (formData) => {
      return await post({
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        type: 'Staff',
        corporateAccountId: formData.corporateAccountId ?? null,
      })
    },
    onSuccess: async (result) => {
      const userData = result.data
      await onSuccess?.(userData)
    },
    onError,
  })
}
