import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from './useTypedApiClient'

export const useGetPatronActivityLog = ({
  patronId,
  numOfDays = 30,
}: {
  patronId: number
  numOfDays: number
}) => {
  const get = useTypedApiClient({
    path: '/admin/activity-logs/attendants/patrons/{patronId}',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/admin/activity-logs-patron', patronId, numOfDays],
    queryFn: async () => {
      const { data } = await get({
        patronId,
        transactionTypes: [
          'CheckInPoint',
          'CheckInPointMultiplier',
          'ManualPoint',
          'MilestoneBirthday',
          'MilestoneWelcome',
          'MilestoneNextTier',
          'MilestoneWelcomeBack',
          'HolidayBonus',
        ],
        numOfDays,
      })
      return data
    },
  })
}
