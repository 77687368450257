import CloseIcon from '@mui/icons-material/Close'
import { type StateFileUpload } from 'types/fileUpload'
import { Box, Button, Typography } from '@mui/material'
import { getFileSizeKb, getFileExtension } from 'utils/util'
import Image from 'assets/image-black.svg?react'

import { useEffect, useState } from 'react'

export const UploadingFile = ({
  file,
  fileFormatsAllowed,
  maxSizeAllowed,
  removeFile,
  toggleStateFilesUpload,
}: {
  file: File
  fileFormatsAllowed: string
  maxSizeAllowed: number
  removeFile: (file: File) => void
  toggleStateFilesUpload: (value: StateFileUpload) => void
}) => {
  const [validateFile, setValidatedFile] = useState<string>()
  useEffect(() => {
    const enumInvalid = 2
    const enumValid = 1
    let validationResult = ''

    // Validate format
    const actualFormat = getFileExtension(file.name)
    validationResult = !fileFormatsAllowed.includes(actualFormat)
      ? 'Failed Wrong file format'
      : ''

    // Validate size and max size
    const actualSizeKb = getFileSizeKb(file.size)
    if (actualSizeKb === 0 && validationResult === '') {
      validationResult = 'Failed File size is 0'
    }
    if (actualSizeKb > maxSizeAllowed && validationResult === '') {
      validationResult = 'Failed File too large'
    }

    setValidatedFile(validationResult)
    toggleStateFilesUpload(validationResult !== '' ? enumInvalid : enumValid)
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '2%' }}>
      <Box className="justify-items-start w-[15%] md:w-[12%] lg:w-[48px]">
        <Image height={32} width={32} />
      </Box>
      <Box sx={{ width: '80%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '75%', lg: '40%', xl: '28%' },
          }}
        >
          <Typography variant="body-3">{file.name}</Typography>
          {validateFile === '' ? (
            <Typography variant="body-3">
              {getFileSizeKb(file.size)} kb
            </Typography>
          ) : (
            <Typography variant="body-3" className={`!text-[#CC2027]`}>
              {validateFile}
            </Typography>
          )}
        </Box>
      </Box>
      <Box className="flex justify-end w-[10%] xl:w-[15%]">
        <Button
          className="flex justify-end"
          style={{
            justifyContent: 'flex-end',
            color: 'rgba(41, 43, 38, 0.6)',
            minWidth: 'auto',
          }}
          type="button"
          onClick={() => {
            removeFile(file)
          }}
          endIcon={<CloseIcon />}
        />
      </Box>
    </Box>
  )
}
