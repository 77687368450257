import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import { Typography } from '@mui/material'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useQuery } from '@tanstack/react-query'
import { useDropdownSweepstakesStore } from 'stores/useDropdownSweepstakesStore'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'
import { type Sweepstake } from 'types/api'
import { useDropdownParticipatingLocationsStore } from 'stores/useDropdownParticipatingLocationStore'

interface SelectDropdownSweepstakesProps {
  liveOnly?: boolean
}

const findSweepstake = ({
  id,
  sweepstakes,
}: {
  id: number
  sweepstakes: Sweepstake[] | undefined
}) => {
  const sweepstake = sweepstakes?.find((x) => x.id === id)

  if (!sweepstake) {
    return <ErrorIndicator />
  }
  return sweepstake?.name ?? null // Return null if not found
}

export const DropdownSweepstakes = ({
  liveOnly,
}: SelectDropdownSweepstakesProps) => {
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  const setDropdownSweepstakesValue = useDropdownSweepstakesStore(
    (state) => state.setdropdownSweepstakesValue
  )
  const setDropdownParticipatingLocationsValue =
    useDropdownParticipatingLocationsStore(
      (state) => state.setdropdownParticipatingLocationsValue
    )

  const get = useTypedApiClient({
    path: '/admin/contests/type/{contestType}',
    method: 'get',
  })
  const { isError, data: contests } = useQuery({
    queryKey: ['sweepstakes'],
    queryFn: async () => {
      const { data } = await get({ contestType: 'Sweepstake' })

      return data
    },
    select: (data: any[]) => {
      let filteredData = data.filter((x) => x.sweepstakeData?.state !== 'Draft')

      if (liveOnly) {
        filteredData = filteredData.filter(
          (x) => x.sweepstakeData?.state === 'Live'
        )
      }

      return filteredData.sort((a, b) => a.name.localeCompare(b.name))
    },
  })

  if (isError) {
    return <Typography>Error</Typography>
  }

  const handleChange = (event: SelectChangeEvent) => {
    setDropdownSweepstakesValue(event.target.value)
    setDropdownParticipatingLocationsValue('')
  }

  return (
    <FormControl>
      <Select
        placeholder="Select Sweepstake"
        fullWidth
        displayEmpty
        input={<OutlinedInput />}
        variant="outlined"
        defaultValue=""
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        }}
        onChange={handleChange}
        renderValue={(selectedId) => {
          if (!selectedId) {
            return <em>Select Sweepstake</em>
          }

          const selectedSweepstake = findSweepstake({
            id: Number(selectedId),
            sweepstakes: contests,
          })

          return selectedSweepstake
        }}
      >
        {contests === undefined ? (
          <MenuItem disabled value="">
            <em>No Data</em>
          </MenuItem>
        ) : (
          contests.map((itm: any) => (
            <MenuItem key={itm.id} value={itm.id}>
              {itm.name}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
}
