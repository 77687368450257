import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetCheckInPolicyByRewardsProgramsId = (id: number) => {
  const get = useTypedApiClient({
    path: '/admin/policies/checkins/rewards-programs/{id}',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/admin/policies/checkins/rewards-programs', id ?? ''],
    queryFn: async () => {
      const { data } = await get({ id })
      return data
    },
  })
}
