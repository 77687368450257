import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  InputLabel,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useQueryClient } from '@tanstack/react-query'
import { usePostLeUserCa } from 'hooks/api/usePostLeUserCa'
import { type CorporateAccount } from 'types/api'
import { AssociatedCorporateAccounts } from './AssociatedCorporateAccounts'
import { array, number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { difference, isNil, pick } from 'lodash'
import { usePutLicensedEstablishmentUser } from 'hooks/api/LicensedEstablishmentUser/usePutLicensedEstablishmentUser'
import { type useUserData } from 'hooks/api/useUserData'
import { ActivityButton } from 'components/ActivityButton'
import { useState, useEffect } from 'react'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'

interface EditAssociatedAccountsModalProps {
  isOpen: boolean
  onCancel: () => void
  leUserId: number
  corporateAccounts: CorporateAccount[]
  user: ReturnType<typeof useUserData>['user']
}

export const EditAssociatedAccountsModal = ({
  isOpen,
  onCancel,
  leUserId,
  corporateAccounts,
  user,
}: EditAssociatedAccountsModalProps) => {
  const [currentCorporateAccounts, setCurrentCorporateAccounts] =
    useState(corporateAccounts)

  useEffect(() => {
    setCurrentCorporateAccounts(corporateAccounts)
  }, [corporateAccounts])

  const queryClient = useQueryClient()
  const createLeUserCa = usePostLeUserCa({
    onSuccess: async () => {
      await Promise.all(
        corporateAccounts.map(
          async (x) =>
            await queryClient.invalidateQueries({
              queryKey: ['corporateAccount', x],
            })
        )
      )

      await queryClient.invalidateQueries({
        queryKey: ['/admin/licensed-establishments/users/{id}', leUserId],
      })
      await queryClient.invalidateQueries({
        queryKey: [
          '/admin/licensed-establishments/users/corporate-accounts/{corporateAccountId}',
        ],
      })
    },
    onError: async (error) => {
      console.error(error)
    },
  })
  const formMethods = useForm({
    resolver: yupResolver(LeUserLocationsSchema),
    defaultValues: {
      licensedEstablishmentUserId: leUserId,
      addCorporateAccountId: '',
      removeCorporateAccountIds: [],
    },
  })
  const { handleSubmit, reset } = formMethods
  const updateLeUser = usePutLicensedEstablishmentUser({
    licensedEstablishmentUserId: leUserId,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/licensed-establishments/users/{id}', leUserId],
      })
      await queryClient.invalidateQueries({
        queryKey: [
          '/admin/licensed-establishments/users/corporate-accounts/{corporateAccountId}',
        ],
      })
      reset()
    },
    onError: async (error) => {
      console.error(error)
    },
  })
  const wrapCancel = () => {
    reset()
    onCancel()
    setCurrentCorporateAccounts(corporateAccounts)
  }
  const wrapSubmit = handleSubmit(
    async ({ addCorporateAccountId, removeCorporateAccountIds }) => {
      if (isNil(removeCorporateAccountIds) || isNil(user)) {
        return
      }

      // The API does not allow for both adding and removal in the same transaction, so we have to separate them with an if-else
      if (addCorporateAccountId !== '') {
        createLeUserCa.mutate({
          corporateAccountId: Number(addCorporateAccountId),
          licensedEstablishmentUserId: leUserId,
          allowed: false,
        })
      } else {
        const userData = user?.licensedEstablishments?.[0]
        updateLeUser.mutate({
          corporateAccountIds: difference(
            corporateAccounts.map((x) => Number(x.id)),
            removeCorporateAccountIds.map((x) => Number(x))
          ),
          licensedEstablishmentIds: user?.licensedEstablishments?.map(
            (x) => x.licensedEstablishmentId
          ),
          organizationIds: user?.organizations?.map((x) => x.organizationId),
          ...pick(user, ['type', 'email', 'firstName', 'lastName']),
          id: user?.id,
          corporateAccountId: 0,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          eulaAccepted: user?.eulaAccepted,
          type: userData?.type ?? 'Staff',
          licensedEstablishmentId: userData?.licensedEstablishmentId,
          leUsersPermission: userData?.leUsersPermission,
          liveAtLocationViewPermission: userData?.liveAtLocationViewPermission,
          locationsPermission: userData?.locationsPermission,
          patronsAccountsPermission: userData?.patronsAccountsPermission,
          promotionsPermission: userData?.promotionsPermission,
          reportsAccessToPIIPermission: userData?.reportsAccessToPIIPermission,
          reportsDownloadPIIPermission: userData?.reportsDownloadPIIPermission,
          reportsFinancialPermission: userData?.reportsFinancialPermission,
          reportsNonFinancialPermission:
            userData?.reportsNonFinancialPermission,
          rewardsPermission: userData?.rewardsPermission,
          sweepstakesPermission: userData?.sweepstakesPermission,
        })
      }

      await queryClient.invalidateQueries({
        queryKey: ['/admin/licensed-establishments/users/{id}', leUserId],
      })
      reset()
      onCancel()
    },
    (errors) => {
      console.error(errors)
    }
  )

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="xl">
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }}>
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Edit Associated Accounts
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            Default permission level for new Corporate Accounts is no access
            until modified by an admin.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={wrapSubmit}>
          <FormProvider {...formMethods}>
            <SelectCorporateAccount
              name="addCorporateAccountId"
              label="Add New Associated Account"
              corporateAccountIdKey="selectCorporateAccount"
              placeholder="Select a Corporate Account"
              idsToFilter={corporateAccounts.map((x) => x.id)}
            />
            <Box className="mt-4">
              <InputLabel>Associated Corporate Accounts</InputLabel>
              <AssociatedCorporateAccounts
                availableCorporateAccounts={currentCorporateAccounts}
              />
            </Box>
          </FormProvider>
        </form>
      </DialogContent>
      <DialogActions className="border-t">
        <Box className="flex flex-row justify-end p-4 gap-x-2">
          <Button onClick={wrapCancel}>Cancel</Button>
          <ActivityButton
            variant="contained"
            type="submit"
            onClick={wrapSubmit}
            active={updateLeUser.isPending || createLeUserCa.isPending}
          >
            Save
          </ActivityButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export const LeUserLocationsSchema = object({
  licensedEstablishmentUserId: number().required(),
  addCorporateAccountId: string().default(''),
  removeCorporateAccountIds: array(number().required()).default([]),
})
