import { isNil } from 'lodash'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'stores/useSnackbar'
import { grey } from '@mui/material/colors'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import { useRewardsProgramTransferPoints } from 'hooks/api/useRewardsProgramTransferPoints'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ActivityButton } from 'components/ActivityButton'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { useGetRewardProgramByLicensedEstablishmentId } from 'hooks/api/useGetRewardProgramByLicensedEstablishmentId'

export const TransferPointsModal = ({
  licensedEstablishmentId,
  rewardProgramId,
  isOpen,
  toggleIsOpen,
}: {
  licensedEstablishmentId: number
  rewardProgramId: number
  isOpen: boolean
  toggleIsOpen: () => void
}) => {
  const [
    selectedTransferRewardsProgramId,
    setSelectedTransferRewardsProgramId,
  ] = useState('')
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const RewardProgramQuery = useGetRewardProgramByLicensedEstablishmentId({
    licensedEstablishmentId,
    includeGlobal: false,
  })
  const eligibleTransferRewardsPrograms = (
    RewardProgramQuery.data ?? []
  ).filter((rp) => rp.id !== rewardProgramId && rp.active)

  const useRewardsProgramTransferPointsMutation =
    useRewardsProgramTransferPoints({
      transferFromRewardsProgramId: rewardProgramId,
      transferToRewardsProgramId: selectedTransferRewardsProgramId
        ? Number(selectedTransferRewardsProgramId)
        : -1,
      onSuccess: () => {
        setSnackbarMessage('Points transferred successfully')
        onCancel()
      },
      onError: () => {
        setSnackbarMessage('Error transferring points', 'error')
      },
    })

  const { handleSubmit } = useForm({})

  const {
    isPending: isPendingRewardProgram,
    data: rewardProgram,
    isError: isErrorRewardProgram,
  } = useGetRewardProgramById(Number(rewardProgramId))

  const onSubmit = handleSubmit(() => {
    useRewardsProgramTransferPointsMutation.mutate({
      transferFromRewardsProgramId: rewardProgramId,
      transferToRewardsProgramId: Number(selectedTransferRewardsProgramId),
    })
  })

  const onCancel = () => {
    setSelectedTransferRewardsProgramId('')
    toggleIsOpen()
  }

  if (
    isPendingRewardProgram ||
    isNil(rewardProgram) ||
    isNil(rewardProgramId)
  ) {
    return <ActivityIndicator />
  }

  if (isErrorRewardProgram) {
    return <p>An error occurred.</p>
  }

  return (
    <Dialog open={isOpen} onClose={toggleIsOpen} maxWidth="sm">
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }}>
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Transfer Points
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Typography variant="body-1" color={'text.secondary'}>
            Transfer Points from this rewards program to another existing
            rewards program linked to this location.
          </Typography>
        </Box>
        <Box className="pt-3">
          <InputLabel htmlFor="selectProgram" required>
            Rewards Program to Transfer to
          </InputLabel>
          <Select
            className="min-w-[200px]"
            labelId="selectProgram"
            variant="outlined"
            required={true}
            value={selectedTransferRewardsProgramId}
            onChange={(e) =>
              setSelectedTransferRewardsProgramId(e.target.value)
            }
          >
            {eligibleTransferRewardsPrograms?.map((rp) => (
              <MenuItem key={rp.id} value={rp.id}>
                {rp.name}
              </MenuItem>
            ))}
          </Select>
          {selectedTransferRewardsProgramId !== '' && (
            <Box className="pt-3">
              <Typography variant="body-1" color={colorPrimitives.redGaming}>
                This will inactivate the current program and transfer all
                current patron points to the selected program. Are you sure?
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions className="border-t">
        <Box className="flex flex-row justify-end p-4 gap-x-2">
          <Button onClick={onCancel}>Cancel</Button>
          <ActivityButton
            variant="contained"
            type="submit"
            onClick={onSubmit}
            disabled={selectedTransferRewardsProgramId === ''}
            active={
              RewardProgramQuery.isFetching || RewardProgramQuery.isPending
            }
          >
            Transfer
          </ActivityButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
