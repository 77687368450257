import { Box, Typography } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import { useLayout } from 'hooks/useLayout'
import {
  dataTableAddressFormatter,
  dataTableLicenseNumberFormatter,
  formatDateTime,
} from 'utils/util'
import { useNavigate } from 'react-router-dom'
import {
  type CorporateAccount,
  type LicensedEstablishment,
  type Organization,
} from 'types/api'
import { CorporateAccountsTreeDataGrid } from 'components/CorporateAccountsTreeDataGrid/CorporateAccountsTreeDataGrid'
import { get, isNil, last } from 'lodash'
import { type components } from 'api/playerPayback/api'
import { colorPrimitives } from 'components/Theme'
import NotFoundLocation from 'assets/locationNotFound.svg'

export interface ParticipatingLocation {
  name: string
  licenseNumber: string
  id: number
  addresses: any
  dateAdded: string
  le: LicensedEstablishment
}

const defaultColumns = (isMobile: boolean): GridColDef[] => [
  {
    field: 'name',
    minWidth: 300,
    headerName: 'Account Name',
    flex: 1,
  },
  {
    field: 'licenseNumber',
    minWidth: 200,
    headerName: 'License #',
    flex: 0.8,
    valueFormatter: dataTableLicenseNumberFormatter(isMobile),
  },
  {
    field: 'addresses',
    minWidth: 500,
    headerName: 'Address',
    flex: 2,
    valueFormatter: dataTableAddressFormatter(true),
    renderCell: (params) => {
      return (
        <p className="overflow-hidden whitespace-nowrap text-ellipsis	">
          {params.formattedValue === 'Unspecified Address'
            ? '-'
            : params.formattedValue}
        </p>
      )
    },
  },
  {
    field: 'contacts.email',
    minWidth: 300,
    headerName: 'Email',
    flex: 1,
    renderCell: (params) => {
      const contacts = params.row.contacts as
        | Array<components['schemas']['FullLicensedEstablishmentContactDTO']>
        | undefined
      return isNil(contacts) || contacts.length === 0 ? (
        <>-</>
      ) : (
        <>{!isNil(last(contacts)?.email) && last(contacts)?.email}</>
      )
    },
  },
  {
    field: 'signage1',
    headerName: 'Signage 1',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      const isLicensedEstablishment =
        get(params.row, 'corporateAccount') !== undefined &&
        get(params.row, 'organization') !== undefined
      return isLicensedEstablishment
        ? params.row.signage1
          ? 'Yes'
          : 'No'
        : '-'
    },
  },
  {
    field: 'signage2',
    headerName: 'Signage 2',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      const isLicensedEstablishment =
        get(params.row, 'corporateAccount') !== undefined &&
        get(params.row, 'organization') !== undefined
      return isLicensedEstablishment
        ? params.row.signage2
          ? 'Yes'
          : 'No'
        : '-'
    },
  },
  {
    field: 'dateAdded',
    headerName: 'Date Enrolled',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      const isLicensedEstablishment =
        get(params.row, 'corporateAccount') !== undefined &&
        get(params.row, 'organization') !== undefined
      return isLicensedEstablishment
        ? params.row.dateAdded
          ? formatDateTime(params.row.dateAdded, 'P p')
          : '-'
        : '-'
    },
  },
]

export const ParticipatingLocationList = ({
  les,
  organizations,
  corporateAccounts,
  contestId,
}: {
  les: LicensedEstablishment[]
  organizations: Organization[]
  corporateAccounts: CorporateAccount[]
  contestId: number
}) => {
  const { isMobile } = useLayout()

  const navigate = useNavigate()

  // const les: LicensedEstablishment[] = participatingLocations.map(
  //   (location) => location.le
  // )

  if (les.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor={colorPrimitives.lightGray}
        padding={{ xs: 7 }}
        marginTop={{ xs: 2 }}
      >
        {/* <img src={notFoundLocation} alt="No data found icon" /> */}
        <img src={NotFoundLocation} />
        <Typography
          variant="label-form"
          marginTop={{ xs: 3 }}
          color="rgba(41, 43, 38, 0.60)"
        >
          No Participating Locations
        </Typography>
      </Box>
    )
  }

  return (
    <Box paddingTop={3}>
      <CorporateAccountsTreeDataGrid
        columns={defaultColumns(isMobile)}
        corporateAccounts={corporateAccounts}
        organizations={organizations}
        licensedEstablishments={les}
        hideFooter={les.length <= 20}
        initialState={{
          pagination: { paginationModel: { pageSize: 50 } },
        }}
        hideFooterSelectedRowCount
        pageSizeOptions={[50, 75, 100]}
        sx={{ maxWidth: '82.5vw' }}
        getRowClassName={() => '.white-background'}
        initialExpanded
        recalculateRows
        onRowClick={(row) => {
          if (row.row.type === 2) {
            const licensedEstablishmentId = row.row.id as number
            navigate(
              `/Contests/Sweepstakes/${contestId}/LicensedEstablishment/${licensedEstablishmentId}/ViewLocationEnrollment`
            )
          }
        }}
      />
    </Box>
  )
}
