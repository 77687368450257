import { compact, uniqBy } from 'lodash'
import { useMemo } from 'react'
import { useGetAllLicensedEstablishments } from './LicensedEstablishment/useGetAllLicensedEstablishments'
import { useGetLicensedEstablishmentSearch } from './LicensedEstablishment/useGetLicensedEstablishmentSearch'

export const useGetLesWithCaAndOrg = ({
  searchKey,
}: {
  searchKey?: string
}) => {
  const getAllLeQuery = useGetAllLicensedEstablishments()

  const getSearchLeQuery = useGetLicensedEstablishmentSearch({
    searchKey,
  })

  const licensedEstablishments = useMemo(() => {
    if (searchKey?.length !== 0) {
      return getSearchLeQuery.data?.filter((x) => x.active)
    }

    return getAllLeQuery.data
  }, [getAllLeQuery, getSearchLeQuery])

  const organizations = useMemo(
    () =>
      Array.isArray(licensedEstablishments) &&
      uniqBy(
        compact(licensedEstablishments?.map((le) => le.organization)),
        'id'
      ),
    [licensedEstablishments]
  )

  const corporateAccounts = useMemo(
    () =>
      Array.isArray(licensedEstablishments) &&
      uniqBy(
        compact(licensedEstablishments?.map((le) => le.corporateAccount)),
        'id'
      ),
    [licensedEstablishments]
  )

  return {
    isPending: getAllLeQuery.isPending,
    isSearchPending: getSearchLeQuery.isPending,
    isError: getAllLeQuery.isError || getSearchLeQuery.isError,
    licensedEstablishments,
    organizations,
    corporateAccounts,
  }
}
