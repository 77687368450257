import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetRewardProgramCatalogByRewardProgramId = (
  rewardProgramId: number
) => {
  const get = useTypedApiClient({
    path: '/admin/rewards-program-catalogs/rewards-program/{rewardProgramId}',
    method: 'get',
  })

  return useQuery({
    queryKey: ['admin-rewards-program-catalogs-by-rp-id'],
    queryFn: async () => {
      const { data } = await get({ rewardProgramId })
      return data
    },
  })
}
