import { type GridColDef } from '@mui/x-data-grid'
import { startCase } from 'lodash'

// Types of exports
export enum LookupType {
  WinnersByContestId,
}

export const getAdminExportColumns = (data: any) => {
  let columns: GridColDef[] = []

  if (data !== undefined) {
    const dataValue = data[0] // Only need the first record to get the headings

    if (dataValue !== undefined) {
      // Add key column to prevent duplicate child keys error
      columns.push({
        field: 'exportKeyId',
        headerName: 'exportKeyId',
        flex: 1,
        sortable: false,
        minWidth: 150,
      })

      columns = Object.keys(dataValue).map((key: any) => ({
        field: key,
        headerName: startCase(key),
        flex: 1,
        minWidth: 250,
      }))
    }
  }

  return columns
}

export const getAdminExportRows = (data: any) => {
  let rowsWithId: any[] = []

  if (data !== undefined) {
    rowsWithId = data.map((row: any, index: number) => ({
      exportKeyId: index + 1,
      ...row,
    }))
  }

  return rowsWithId
}
