import { useMemo, useState } from 'react'
import {
  Box,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  Typography,
  type SelectChangeEvent,
} from '@mui/material'
import {
  DataGrid,
  type GridRowSelectionModel,
  type GridColDef,
  type GridRowId,
} from '@mui/x-data-grid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useQuery } from '@tanstack/react-query'
import { ApiError } from 'openapi-typescript-fetch'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebounce } from '@uidotdev/usehooks'
import { SearchField } from 'components/SearchField'
import { colorPrimitives } from 'components/Theme'
import { type RewardType, type Patron } from 'types/api'
import { colors } from 'components/ThemeProvider'
import CloseIcon from 'assets/close-filled.svg?react'
import { useSnackbar } from 'stores/useSnackbar'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useLayout } from 'hooks/useLayout'
import { useGetRewardsOptionsById } from 'hooks/api/useGetRewardsOptionsById'
import { verifyAddress } from 'utils/util'
import PatronAccountsIcon from 'assets/nav_icons/patron_accounts.svg?react'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isNil } from 'lodash'
import { usePostRedeemFreeReward } from 'hooks/api/usePostRedeemFreeReward'
import { ActivityButton } from 'components/ActivityButton'

interface RewardItemRow {
  id: number
  image: string
  rewardId: number
  rewardItemName: string
  type: RewardType
}

export const SendRewardSection = () => {
  const { id: rewardsProgramId } = useParams()
  const [query, setQuery] = useState('')
  const [selectedPatron, setSelectedPatron] = useState<Patron>()
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedRewardItem, setSelectedRewardItem] = useState<RewardItemRow>()
  const [selectedOption, setSelectedOption] = useState('')
  const [errors, setErrors] = useState<string[]>([])
  const debouncedQuery = useDebounce(query, 500)
  const { isMobile } = useLayout()
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])
  const [rewardSearchString, setRewardSearchString] = useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value)
    setErrors([])
  }

  const handleSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedRows(rowSelectionModel)
  }

  // Function to programmatically unselect a row
  const unselectRow = (rowId: string) => {
    const newSelectedRows = selectedRows?.filter((id) => id !== rowId)
    setSelectedRows(newSelectedRows)
  }

  const columns: GridColDef[] = [
    {
      field: 'image',
      sortable: false,
      headerName: 'Image',
      headerClassName: 'pl-5',
      renderCell: (params) => (
        <img alt="reward" style={{ height: '64px' }} src={params.value} />
      ),
      flex: 1,
      renderHeader: (params) => (
        <Typography marginLeft={4} variant="subtitle-1">
          {params.colDef.headerName}
        </Typography>
      ),
    },
    {
      field: 'rewardItemName',
      headerName: 'Reward Item Name',
      flex: 2,
      renderCell: (params) => (
        <Typography color={colorPrimitives.black}>{params.value}</Typography>
      ),
    },
    {
      field: 'rewardId',
      headerName: 'Reward ID',
      flex: 2,
    },
    {
      field: 'isSelected',
      flex: 1,
      sortable: false,
      align: 'right',
      renderHeader: () => {
        return ''
      },
      renderCell: (params) => {
        return selectedItem === params.row.id ? (
          <CheckCircleIcon
            color="primary"
            sx={{
              width: '32px',
              height: '32px',
              marginX: '8px',
            }}
          />
        ) : (
          ''
        )
      },
    },
  ]
  const mobileColumns: GridColDef[] = [
    {
      sortable: false,
      field: 'image',
      headerName: 'Image',
      renderCell: (params) => (
        <img alt="reward" style={{ height: '64px' }} src={params.value} />
      ),
      renderHeader: (params) => (
        <Typography variant="subtitle-1">{params.colDef.headerName}</Typography>
      ),
    },
    {
      field: 'rewardItemName',
      headerName: 'Reward Item',
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <Typography color={colorPrimitives.black}>
              {params.row.rewardItemName}
            </Typography>
            <Typography color={colorPrimitives.warnGray5}>
              {params.row.rewardId}
            </Typography>
          </div>
        )
      },
    },
    {
      field: 'isSelected',
      disableReorder: true,
      sortable: false,
      flex: 1,
      align: 'right',
      renderHeader: () => {
        return ''
      },
      renderCell: (params) => {
        return selectedItem === params.row.id ? (
          <CheckCircleIcon
            color="primary"
            sx={{
              width: '32px',
              height: '32px',
              marginX: '8px',
            }}
          />
        ) : (
          ''
        )
      },
    },
  ]

  const searchPatrons = useTypedApiClient({
    path: '/admin/patrons/search',
    method: 'get',
  })
  const rewardItemsByProgram = useTypedApiClient({
    path: '/admin/rewards-catalog-rewards/reward-programs/{id}',
    method: 'get',
  })

  const patronsQuery = useQuery<Patron[], ApiError>({
    queryKey: ['patrons', debouncedQuery],
    queryFn: async () => {
      if (debouncedQuery.length >= 2) {
        return (await searchPatrons({ query: debouncedQuery })).data
      } else {
        return []
      }
    },
  })

  const { data: programRewardItems } = useQuery({
    queryKey: [],
    queryFn: async () => {
      return (await rewardItemsByProgram({ id: Number(rewardsProgramId) })).data
    },
  })

  const rewardItemTableRows = programRewardItems
    ? programRewardItems.map((item) => ({
        id: item.id,
        image: item.reward?.publicImageUrl,
        rewardItemName: item.reward?.name,
        rewardId: item.rewardId,
        type: item.reward?.type,
      }))
    : []
  const rewardIds = rewardItemTableRows.map(({ rewardId }) => rewardId)

  const dedupedRows = rewardItemTableRows.filter(
    ({ rewardId }, index) => !rewardIds.includes(rewardId, index + 1)
  )

  const { data: rewardsOptionsQuery } = useGetRewardsOptionsById({
    rewardId: Number(selectedRewardItem?.rewardId),
    type: 'Size',
    enableJJStore: selectedRewardItem?.type === 'JJStoreItem',
  })

  const rewardsOptionsValues =
    isNil(rewardsOptionsQuery) ||
    isNil(rewardsOptionsQuery?.value) ||
    rewardsOptionsQuery.type === 'Unknown'
      ? undefined
      : rewardsOptionsQuery?.value.split(',')

  const handlePatronClick = (e: any) => {
    let patronId = ''
    if (e.target.nodeName === 'P') {
      const splitText = e.target.innerText.split(' ')
      patronId = splitText[splitText.length - 1]
    } else {
      patronId = e.target.value
    }
    const patronById = patronsQuery.data?.filter(
      (patron) => patron.id === Number(patronId)
    )[0]
    setSelectedPatron(patronById)
  }
  const onClosePatron = () => {
    setErrors([])
    setQuery('')
    setSelectedItem('')
    setSelectedPatron(undefined)
    setSelectedRewardItem(undefined)
  }
  const onCloseRewardItem = () => {
    setErrors([])
    setSelectedItem('')
    setSelectedOption('')
    setSelectedRewardItem(undefined)
  }
  const onRowClick = (e: any) => {
    setSelectedItem(e.id)
    setSelectedRewardItem(e.row)
  }

  const patronAddress = selectedPatron?.addresses?.[0]
  const usePostRedeemFreeRewardMutation = usePostRedeemFreeReward({
    selectedPatron,
    selectedRewardItem,
    rewardsProgramId,
    rewardsOptionsQuery,
    selectedOption,
    patronAddress,
    email: selectedPatron?.email,
    programRewardItems,
    onSuccess: () => {
      setSnackbarMessage(
        `${selectedRewardItem?.rewardItemName} successfully sent to ${selectedPatron?.firstName} ${selectedPatron?.lastName} - ${selectedPatron?.id}`
      )
      navigate(`/Rewards/RewardPrograms`)
    },
    onError: (error: { message: string }) =>
      setSnackbarMessage(
        error instanceof ApiError
          ? `Error sending reward: ${error.message}`
          : `Error sending reward`,
        'error'
      ),
  })

  const onSendReward = async () => {
    if (
      selectedRewardItem?.type === 'JJStoreItem' &&
      rewardsOptionsValues &&
      !selectedOption.length
    ) {
      setErrors(['selectedOption'])
      return
    }

    if (selectedRewardItem && !errors.length) {
      if (selectedPatron && verifyAddress(patronAddress)) {
        usePostRedeemFreeRewardMutation.mutate()
      } else {
        setSnackbarMessage(`No address available for patron`, 'error')
      }
    } else if (selectedPatron) {
      usePostRedeemFreeRewardMutation.mutate()
    } else {
      setSnackbarMessage(`Error with selected Patron`)
    }
    setErrors([])
  }

  const dedupedFilteredRewardItemRows = useMemo(() => {
    return dedupedRows.filter((row) => {
      if (row.rewardItemName) {
        return (
          row.rewardItemName
            .toLowerCase()
            .includes(rewardSearchString.toLowerCase()) ||
          row.rewardId.toString().includes(rewardSearchString.toLowerCase())
        )
      } else {
        return true
      }
    })
  }, [dedupedRows, rewardSearchString])

  return (
    <Box>
      {/* Search Patron ID or Name Section */}
      <Typography variant="h6">Search Patron ID or Name</Typography>
      <Typography variant="body-2" color={'rgba(44, 44, 39, 0.68)'}>
        Make sure to use a minimum of at least 2 characters per word when
        searching.
      </Typography>
      <SearchField
        placeholder="Start typing to search"
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
        sx={{ my: '16px' }}
      />
      {!!patronsQuery.data?.length && !selectedPatron && (
        <Paper variant="outlined" sx={{ mt: '7px', width: '520px' }}>
          {patronsQuery.data?.map((patron) => {
            let resultString = `${patron.firstName} ${patron.lastName} - ${patron.id}`
            resultString = resultString.replace(
              query,
              `<strong>${query}</strong>`
            )
            return (
              <MenuItem
                key={patron.id}
                value={patron.id}
                onClick={handlePatronClick}
              >
                <p dangerouslySetInnerHTML={{ __html: resultString }} />
              </MenuItem>
            )
          })}
        </Paper>
      )}
      {selectedPatron && (
        <Box
          sx={{
            backgroundColor: colorPrimitives.lightGray,
            p: '12px',
          }}
          maxWidth={'520px'}
        >
          <div className="flex justify-between items-center">
            <div className="flex flex-row items-center">
              <SvgIcon
                sx={{
                  color: colors.text.primary,
                  transform: 'translate(0, -2px)',
                  height: '40px',
                  width: '40px',
                  marginRight: '10px',
                }}
              >
                <PatronAccountsIcon />
              </SvgIcon>
              <div className="flex flex-col">
                <Typography variant="body-1">
                  {selectedPatron?.firstName} {selectedPatron?.lastName}
                </Typography>
                <Typography variant="body-1" pb={1} color="text.secondary">
                  {selectedPatron?.id}
                </Typography>
              </div>
            </div>
            <Box
              onClick={onClosePatron}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <SvgIcon
                sx={{
                  transform: 'translate(0, -2px)',
                  height: '24px',
                  width: '24px',
                }}
              >
                <CloseIcon />
              </SvgIcon>
            </Box>
          </div>
        </Box>
      )}

      {/* Select Reward section */}
      {selectedPatron && (
        <Box
          sx={{
            marginTop: '40px',
          }}
        >
          <Typography variant="h4" marginBottom={'16px'}>
            Select Reward
          </Typography>
          <SearchField
            placeholder="Search Reward Items"
            onChange={(e) => setRewardSearchString(e.target.value)}
            fullWidth
            sx={{ my: '16px' }}
          />
          {isMobile ? (
            <DataGrid
              sx={{
                '& .d-none .MuiDataGrid-columnHeaderDraggableContainer': {
                  display: 'none',
                },
              }}
              rowHeight={80}
              rows={dedupedFilteredRewardItemRows}
              onRowClick={onRowClick}
              columns={mobileColumns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 20 },
                },
              }}
              pageSizeOptions={[5, 10, 20]}
            />
          ) : (
            <DataGrid
              sx={{
                '& .d-none .MuiDataGrid-columnHeaderDraggableContainer': {
                  display: 'none',
                },
              }}
              rowHeight={80}
              rows={dedupedFilteredRewardItemRows}
              onRowClick={onRowClick}
              columns={columns}
              rowSelectionModel={selectedRows}
              onRowSelectionModelChange={handleSelectionChange}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 20 },
                },
              }}
              pageSizeOptions={[5, 10, 20]}
              hideFooter={dedupedFilteredRewardItemRows.length < 20}
              hideFooterSelectedRowCount
            />
          )}
        </Box>
      )}

      {selectedRewardItem && (
        <Box
          sx={{
            backgroundColor: colorPrimitives.lightGray,
            p: '12px',
          }}
          maxWidth={'520px'}
          marginTop={4}
          marginBottom={3}
        >
          <div className="flex justify-between items-center">
            <div className="flex flex-row items-center w-full">
              <img
                alt="selected reward"
                style={{ height: '64px', marginRight: '10px' }}
                src={selectedRewardItem.image}
              />
              <Box
                className={rewardsOptionsValues && 'flex w-full justify-around'}
              >
                <div>
                  <Typography variant="body-1">
                    {selectedRewardItem.rewardItemName}
                  </Typography>
                  <Typography variant="body-1" pb={1} color="text.secondary">
                    {selectedRewardItem.rewardId}
                  </Typography>
                </div>
                {selectedRewardItem.type === 'JJStoreItem' &&
                  rewardsOptionsValues && (
                    <Box className="w-1/2">
                      <Typography variant="body-1">Select Size</Typography>
                      <Select
                        className="bg-white"
                        notched={false}
                        displayEmpty
                        fullWidth
                        value={selectedOption}
                        label="Size"
                        onChange={handleChange}
                        renderValue={() =>
                          selectedOption.length === 0 ? 'Size' : selectedOption
                        }
                      >
                        {rewardsOptionsValues?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.includes('selectedOption') && (
                        <FormFieldErrorMessage message={'Must select a size'} />
                      )}
                    </Box>
                  )}
              </Box>
            </div>
            <Box
              onClick={() => {
                onCloseRewardItem()
                unselectRow(selectedItem)
              }}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <SvgIcon
                sx={{
                  transform: 'translate(0, -2px)',
                  height: '24px',
                  width: '24px',
                }}
              >
                <CloseIcon />
              </SvgIcon>
            </Box>
          </div>
        </Box>
      )}

      {selectedRewardItem && (
        <ActivityButton
          active={usePostRedeemFreeRewardMutation.isPending}
          variant="contained"
          onClick={onSendReward}
        >
          Send Reward
        </ActivityButton>
      )}
    </Box>
  )
}
