import React, { useState } from 'react'
import { isNil } from 'lodash'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { PatronDetailsHeader } from '../DetailsHeader/PatronDetailsHeader'
import { Box, Typography, Button, MenuItem, Link, Stack } from '@mui/material'
import { DataTable } from 'components/Shared/DataTable'
import { type GridColDef } from '@mui/x-data-grid'
import { formatDateTime } from 'utils/util'
import {
  type RewardsRedemption,
  type PointsLedgerEntry,
  type TransactionType,
} from 'types/api'
import { useGetPatronActivityLog } from 'hooks/useGetPatronActivityLog'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import { colorPrimitives } from 'components/Theme'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'

export const PatronActivityLogPage = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [numDays, setNumDays] = React.useState<number>(30)
  const [filteredData, setFilteredData] = useState<ActivityLog[]>()
  const open = Boolean(anchorEl)

  const handleFilteredData = (filteredData: ActivityLog[]) => {
    setFilteredData(filteredData)
  }
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuItemClose = (val: number) => {
    setNumDays(val)
    setAnchorEl(null)
  }

  const { id: patronAccountId } = useParams()

  const patronQuery = useGetPatronById(Number(patronAccountId))

  const patronActivityLogQuery = useGetPatronActivityLog({
    patronId: Number(patronAccountId),
    numOfDays: numDays,
  })

  if (patronQuery.isPending || patronActivityLogQuery.isPending) {
    return <ActivityIndicator />
  }

  if (
    patronQuery.isError ||
    isNil(patronQuery.data) ||
    patronActivityLogQuery.isError ||
    isNil(patronActivityLogQuery.data)
  ) {
    return <ErrorIndicator message="An Error Occurred" />
  }

  const pointsLedgerData = patronActivityLogQuery.data.pointsLedgerEntries ?? []
  const sweepstakesData = patronActivityLogQuery.data.patronCheckIns ?? []
  const rewardRedemptionsData =
    patronActivityLogQuery.data.rewardRedemptions ?? []

  interface ActivityLog {
    log: string
    points: string | number
    rewardProgramName?: string
    rewardProgramId?: number
    licensedEstablishmentId: number | null
    licensedEstablishmentName?: string | null
    rewardedBy?: string | null
    options?: string | null
    timeStamp?: string | null
  }

  const pointsLedgerLogMapping = (entry: PointsLedgerEntry) => {
    const transactionTypeLabelMap: Record<TransactionType, string> = {
      CheckInPoint:
        entry?.rewardsProgram?.type === 'Global'
          ? 'Global Check In'
          : 'Location Check In',
      MilestoneWelcome: 'Welcome Bonus',
      MilestoneWelcomeBack: 'Welcome Back Bonus',
      MilestoneBirthday: 'Birthday Bonus',
      MilestoneNextTier: 'Tier Bonus',
      CheckInPointMultiplier: 'Multiplier Bonus',
      ManualPoint: 'Points Manually Rewarded',
      HolidayBonus: 'Holiday Bonus',
      Redemption: '',
      ManualReward: '',
    }

    return transactionTypeLabelMap[entry.transactionType]
  }

  const pointsLedgerActivity: ActivityLog[] = pointsLedgerData.map(
    (entry: PointsLedgerEntry) => ({
      id: entry.id,
      log: pointsLedgerLogMapping(entry),
      points: entry.amount,
      rewardProgramName: entry.rewardsProgram?.name,
      rewardProgramId: entry.rewardsProgram?.id,
      licensedEstablishmentId:
        entry.patronCheckIn?.licensedEstablishmentId ?? null,
      licensedEstablishmentName: entry.patronCheckIn?.licensedEstablishment
        ?.standardName
        ? entry.patronCheckIn?.licensedEstablishment?.standardName
        : entry.patronCheckIn?.licensedEstablishment?.name,
      licenseNumber:
        entry.patronCheckIn?.licensedEstablishment?.licenseNumber ?? null,
      rewardedBy:
        entry.transactionType === 'ManualPoint' ? entry.createdBy : '-',
      options: '-',
      timeStamp: entry.createdOn,
    })
  )

  const rewardRedemptionsActivity: ActivityLog[] = rewardRedemptionsData.map(
    (redemption: RewardsRedemption) => ({
      id: redemption.id,
      log: `${String(redemption.rewardsCatalogReward?.reward?.name)} ${
        redemption.type === 'PatronRedemption' ? 'Redeemed' : 'Manually Awarded'
      }`,
      points:
        redemption.type === 'ManualReward'
          ? redemption.amount
          : `-${String(redemption.rewardsCatalogReward?.amount)}`,
      rewardProgramName: redemption.rewardsProgram?.name,
      licensedEstablishmentId:
        redemption.rewardsProgram?.licensedEstablishment?.id ?? null,
      licensedEstablishmentName: redemption.rewardsProgram
        ?.licensedEstablishment?.standardName
        ? redemption.rewardsProgram?.licensedEstablishment?.standardName
        : redemption.rewardsProgram?.licensedEstablishment?.name,
      licenseNumber:
        redemption.rewardsProgram?.licensedEstablishment?.licenseNumber ?? null,
      rewardedBy:
        redemption.type === 'ManualReward' &&
        redemption.createdBy !== 'JJVentures.PlayerPAYBACK.WebApi'
          ? redemption.createdBy
          : '-',
      options:
        redemption.options?.value != null
          ? `${String(redemption.options?.type)}: ${String(
              redemption.options?.value
            )}`
          : '-',
      timeStamp: redemption.transactionDate,
    })
  )

  let sweepstakesActivity: ActivityLog[] = []
  sweepstakesData.forEach((itm) => {
    const sweepstakesActivityMapped: any = itm.contestEntries?.map(
      (sweepstake: any) => ({
        id: sweepstake.id,
        log: sweepstake.contest?.name,
        points: '-',
        rewardProgramName: '-',
        rewardProgramId: '-',
        licensedEstablishmentId: itm.licensedEstablishmentId ?? null,
        licensedEstablishmentName: itm.licensedEstablishment?.standardName
          ? itm.licensedEstablishment?.standardName
          : itm.licensedEstablishment?.name,
        licenseNumber: itm.licensedEstablishment?.licenseNumber ?? null,
        rewardedBy: '-',
        options: '-',
        timeStamp: itm.createdOn,
      })
    )
    sweepstakesActivity = sweepstakesActivity.concat(sweepstakesActivityMapped)
  })

  const activityLogData = pointsLedgerActivity
    .concat(rewardRedemptionsActivity)
    .concat(sweepstakesActivity)

  const ActivityLogColumns: Array<GridColDef<ActivityLog>> = [
    {
      field: 'log',
      headerName: 'Activity Log',
      minWidth: 400,
    },
    {
      field: 'points',
      headerName: 'Points',
      minWidth: 120,
    },
    {
      field: 'rewardProgram',
      headerName: 'Reward Program',
      minWidth: 400,
      renderCell: (params) => (
        <Link
          to={`/Rewards/RewardPrograms/${params.row.rewardProgramId}`}
          component={RouterLink}
          underline="hover"
          color="text.primary"
          sx={{
            '&:hover': {
              color: '#1A4F64',
            },
          }}
        >
          <Typography>{params.row.rewardProgramName}</Typography>
        </Link>
      ),
    },
    {
      field: 'leName',
      headerName: 'LE Name',
      minWidth: 250,
      renderCell: (params) => (
        <Link
          to={`/LicensedEstablishments/${params.row.licensedEstablishmentId}`}
          component={RouterLink}
          underline="hover"
          color="text.primary"
          sx={{
            '&:hover': {
              color: colorPrimitives.feltBlue,
            },
            pointerEvents: params.row.licensedEstablishmentId ? 'auto' : 'none',
          }}
        >
          <Typography>{params.row.licensedEstablishmentName ?? '-'}</Typography>
        </Link>
      ),
    },
    {
      field: 'licenseNumber',
      headerName: 'License Number',
      minWidth: 200,
      valueFormatter: (params: { value: string }) => {
        return params.value ? `#${params.value}` : '-'
      },
    },
    {
      field: 'rewardedBy',
      headerName: 'Manually Rewarded By',
      minWidth: 240,
    },
    {
      field: 'options',
      headerName: 'Option',
      minWidth: 180,
    },
    {
      field: 'timeStamp',
      headerName: 'Timestamp',
      minWidth: 180,
      valueFormatter: (params: { value: string }) => {
        return formatDateTime(params.value, 'MM-dd-yyyy p')
      },
    },
  ]

  return (
    <PatronDetailsHeader
      patron={patronQuery.data}
      currentTab={`/PatronAccounts/${patronAccountId}/ActivityLog`}
    >
      <Box paddingBottom={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom={3}
        >
          <Typography variant={'h3'}>Activity Log</Typography>
          <Button
            id="activity-day-button"
            sx={{ display: 'flex', flexDirection: 'row' }}
            onClick={handleMenuClick}
          >
            <Typography variant="body-1">Last {numDays} days</Typography>
            {open ? (
              <KeyboardArrowUpIcon
                sx={{
                  width: '22px',
                  ml: '4px',
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{
                  width: '22px',
                  ml: '4px',
                }}
              />
            )}
          </Button>
          <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              '& .MuiPaper-root': {
                marginTop: 0,
                minWidth: 340,
              },
            }}
          >
            <MenuItem onClick={() => handleMenuItemClose(30)}>30 Days</MenuItem>
            <MenuItem onClick={() => handleMenuItemClose(90)}>90 Days</MenuItem>
            <MenuItem onClick={() => handleMenuItemClose(365)}>
              365 Days
            </MenuItem>
          </StyledMenu>
        </Stack>

        <GridSearchField<ActivityLog>
          placeholder="Search Activity Logs"
          data={activityLogData}
          handleFilteredData={handleFilteredData}
          fullWidth
        />

        <DataTable
          sortFieldName="timeStamp"
          sort="desc"
          columns={ActivityLogColumns}
          rows={filteredData ?? activityLogData}
          sx={{
            '& .MuiDataGrid-cell': {
              color: colorPrimitives.black,
            },
          }}
        />
      </Box>
    </PatronDetailsHeader>
  )
}
