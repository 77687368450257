import { Typography, SvgIcon } from '@mui/material'
import MemberIcon from 'assets/tiers/Member.svg?react'
import VipIcon from 'assets/tiers/VIP.svg?react'
import EliteIcon from 'assets/tiers/Elite.svg?react'
import InsiderIcon from 'assets/tiers/Insider.svg?react'

export const PatronTierSubtitle = ({
  patronTier,
}: {
  patronTier: string | undefined
}) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: 'bold',
        }}
        color={'text.primary'}
        variant="body-3"
        className="whitespace-pre-line"
      >
        {patronTier}
      </Typography>

      {patronTier && patronTier === 'Member' && (
        <SvgIcon
          sx={{
            transform: 'translate(0px,-3px)',
            marginLeft: '7px',
            marginRight: '11px',
          }}
        >
          <MemberIcon />
        </SvgIcon>
      )}
      {patronTier && patronTier === 'Insider' && (
        <SvgIcon
          sx={{
            marginLeft: '10px',
            marginRight: '15px',
          }}
        >
          <InsiderIcon />
        </SvgIcon>
      )}
      {patronTier && patronTier === 'Elite' && (
        <SvgIcon
          sx={{
            transform: 'translate(0px,-3px)',
            marginLeft: '8px',
            marginRight: '11px',
          }}
        >
          <EliteIcon />
        </SvgIcon>
      )}
      {patronTier && patronTier === 'VIP' && (
        <SvgIcon
          sx={{
            transform: 'translate(0px,-2px)',
            marginLeft: '8px',
            marginRight: '11px',
          }}
        >
          <VipIcon />
        </SvgIcon>
      )}
    </>
  )
}
