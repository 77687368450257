import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetParticipatingLocations = ({
  contestId,
}: {
  contestId: number
}) => {
  const get = useTypedApiClient({
    path: '/admin/contests/{contestId}/licensed-establishments/participating',
    method: 'get',
  })
  return useQuery({
    queryKey: [
      '/admin/contests/{contestId}/licensed-establishments/participating',
      contestId,
    ],
    queryFn: async () => {
      const { data } = await get({ contestId })
      return data
    },
  })
}
