import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { type components } from 'api/playerPayback/api'
import type { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid'
import { chain, get, flatten } from 'lodash'
import { grey } from '@mui/material/colors'
import { CorporateAccountsTreeDataGrid } from 'components/CorporateAccountsTreeDataGrid/CorporateAccountsTreeDataGrid'
import { useGetAllCorporateAccounts } from 'hooks/api/useGetAllCorporateAccounts'
import { useGetAllOrganizations } from 'hooks/api/useGetAllOrganizations'
import { useGetAllLicensedEstablishments } from 'hooks/api/LicensedEstablishment/useGetAllLicensedEstablishments'
import {
  dataTableAddressFormatter,
  dataTableLicenseNumberFormatter,
} from 'utils/util'
import { useLayout } from 'hooks/useLayout'
import { useState, useEffect } from 'react'
import { HandleOnChangeSelection } from 'pages/Contests/Sweeptakes/SweepstakesEnrollLocations/HandleOnChangeSelection'
import { SearchField } from 'components/SearchField'
import { type InferType } from 'yup'
import { type promotionLicensedEstablishmentArraySchema } from 'utils/schemas/promotionSchema'

interface ChooseLocationsModalProps {
  isModalOpen: boolean
  toggleIsOpen: () => void
  saveLocations: (
    locations: InferType<typeof promotionLicensedEstablishmentArraySchema>
  ) => void
  defaultLocations: string[]
  defaultPromotionId: number
}

const defaultColumns = (isMobile: boolean): GridColDef[] => [
  {
    field: 'name',
    minWidth: 300,
    headerName: 'Account Name',
    flex: 1,
  },
  {
    field: 'licenseNumber',
    minWidth: 200,
    headerName: 'License #',
    flex: 0.8,
    valueFormatter: dataTableLicenseNumberFormatter(isMobile),
  },
  {
    field: 'addresses',
    minWidth: 500,
    headerName: 'Address',
    flex: 2,
    valueFormatter: dataTableAddressFormatter(true),
    renderCell: (params) => {
      return (
        <p className="overflow-hidden whitespace-nowrap text-ellipsis	">
          {params.formattedValue === 'Unspecified Address'
            ? '-'
            : params.formattedValue}
        </p>
      )
    },
  },
]

export const ChooseLocationsModal = ({
  isModalOpen,
  toggleIsOpen,
  saveLocations,
  defaultLocations,
  defaultPromotionId,
}: ChooseLocationsModalProps) => {
  const { isMobile } = useLayout()
  const { data: allCorporateAccounts } = useGetAllCorporateAccounts()
  const { data: allOrganizations } = useGetAllOrganizations()
  const { data: allLicensedEstablishments } = useGetAllLicensedEstablishments()

  const [savedRowKeys, setSavedRowKeys] = useState<string[]>([
    ...defaultLocations,
  ])
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([
    ...defaultLocations,
  ])
  const [searchKey, setSearchKey] = useState<string>('')
  const [filteredCorporateAccounts, setFilteredCorporateAccounts] = useState<
    Array<components['schemas']['FullCorporateAccountDTO']>
  >([])
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    Array<components['schemas']['FullOrganizationDTO']>
  >([])
  const [filteredLicensedEstablishments, setFilteredLicensedEstablishments] =
    useState<Array<components['schemas']['FullLicensedEstablishmentDTO']>>([])

  useEffect(() => {
    if (searchKey) {
      const filteredLicensedEstablishments =
        (Array.isArray(allLicensedEstablishments) &&
          allLicensedEstablishments?.filter(
            (le) =>
              le.standardName
                ?.toLowerCase()
                .includes(searchKey.toLowerCase()) && le.active
          )) ||
        []
      const corpAcctIds =
        Array.isArray(filteredLicensedEstablishments) &&
        filteredLicensedEstablishments?.map((le) => le.corporateAccountId)
      const organizationIds =
        Array.isArray(filteredLicensedEstablishments) &&
        filteredLicensedEstablishments?.map((le) => le.organizationId)
      const filteredCorporateAccounts = allCorporateAccounts?.filter(
        (ca) => Array.isArray(corpAcctIds) && corpAcctIds?.includes(ca.id)
      )
      const filteredOrganizations = allOrganizations?.filter(
        (org) =>
          Array.isArray(organizationIds) && organizationIds?.includes(org.id)
      )
      setFilteredCorporateAccounts(filteredCorporateAccounts ?? [])
      setFilteredOrganizations(filteredOrganizations ?? [])
      setFilteredLicensedEstablishments(filteredLicensedEstablishments ?? [])
    } else {
      const filteredLicensedEstablishments =
        (Array.isArray(allLicensedEstablishments) &&
          allLicensedEstablishments?.filter((le) => le.active)) ||
        []
      const corpAcctIds =
        Array.isArray(filteredLicensedEstablishments) &&
        filteredLicensedEstablishments?.map((le) => le.corporateAccountId)
      const organizationIds =
        Array.isArray(filteredLicensedEstablishments) &&
        filteredLicensedEstablishments?.map((le) => le.organizationId)
      const filteredCorporateAccounts = allCorporateAccounts?.filter(
        (ca) => Array.isArray(corpAcctIds) && corpAcctIds?.includes(ca.id)
      )
      const filteredOrganizations = allOrganizations?.filter(
        (org) =>
          Array.isArray(organizationIds) && organizationIds?.includes(org.id)
      )

      setFilteredCorporateAccounts(filteredCorporateAccounts ?? [])
      setFilteredOrganizations(filteredOrganizations ?? [])
      setFilteredLicensedEstablishments(filteredLicensedEstablishments ?? [])
    }
  }, [
    searchKey,
    allCorporateAccounts,
    allOrganizations,
    allLicensedEstablishments,
  ])

  const handleOnChange = (rowSelectionModel: GridRowSelectionModel) => {
    const selectedRows = new HandleOnChangeSelection({
      previousRows: selectedRowKeys,
      rows: flatten(rowSelectionModel.map((x) => String(x))),
      licensedEstablishments: filteredLicensedEstablishments ?? [],
      enrolledLicensedEstablishmentIds: [],
      organizations: filteredOrganizations ?? [],
      corporateAccounts: filteredCorporateAccounts ?? [],
      selectedRows: selectedRowKeys,
    }).handleCorporateAccountAndOrgSelection()
    setSelectedRowKeys(selectedRows)
  }

  const handleSave = () => {
    setSavedRowKeys([...selectedRowKeys])
    const justLeLocations = chain(selectedRowKeys)
      .map((x) => {
        const base = x.split('-')
        const type = base[0]
        const id = Number(base[1])
        if (type === 'licensedEstablishment') {
          return id
        }
        return null
      })
      .compact()
      .value()

    const mappedLocations = justLeLocations.map((id) => ({
      promotionId: defaultPromotionId,
      licensedEstablishmentId: id,
    }))
    saveLocations(mappedLocations)
    toggleIsOpen()
    setSearchKey('')
  }

  const handleCancel = () => {
    console.log('cancel......')
    console.log([...savedRowKeys])
    setSelectedRowKeys([...savedRowKeys])
    toggleIsOpen()
    setSearchKey('')
  }

  return (
    <Dialog open={isModalOpen} maxWidth={'xl'} fullWidth onClose={toggleIsOpen}>
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Choose Locations
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 4, px: 4 }}>
        <Box paddingBottom={2} paddingTop={3}>
          <SearchField
            placeholder="Search"
            onChange={(e) => {
              setSearchKey(e.target.value)
            }}
            sx={{ paddingBottom: 3, width: '520px' }}
            fullWidth={!isMobile}
          />
          <CorporateAccountsTreeDataGrid
            recalculateRows={true}
            columns={defaultColumns(isMobile)}
            checkboxSelection
            keepNonExistentRowsSelected
            corporateAccounts={filteredCorporateAccounts ?? []}
            organizations={filteredOrganizations ?? []}
            licensedEstablishments={filteredLicensedEstablishments ?? []}
            hideFooter={
              filteredLicensedEstablishments &&
              filteredLicensedEstablishments?.length <= 20
            }
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
            pageSizeOptions={[20, 75, 100]}
            initialExpanded
            rowSelectionModel={selectedRowKeys}
            hideFooterSelectedRowCount
            onRowSelectionModelChange={handleOnChange}
            getRowClassName={(params) => {
              const id = Number(params.row.id)
              const isLicensedEstablishment =
                get(params.row, 'corporateAccount') !== undefined &&
                get(params.row, 'organization') !== undefined
              const isCorporateAccount =
                get(params.row, 'smartID') !== undefined
              const isOrganization =
                get(params.row, 'parentOrganization') !== undefined

              if (isLicensedEstablishment) {
                const isSelected =
                  selectedRowKeys.find(
                    (x) => x === `licensedEstablishment-${id}`
                  ) !== undefined

                if (isSelected) {
                  return 'selected'
                }
              } else if (isOrganization) {
                const isSelected =
                  selectedRowKeys.find((x) => x === `organization-${id}`) !==
                  undefined

                if (isSelected) {
                  return 'selected'
                }
              } else if (isCorporateAccount) {
                const isSelected =
                  selectedRowKeys.find(
                    (x) => x === `corporateAccount-${id}`
                  ) !== undefined

                if (isSelected) {
                  return 'selected'
                }
              }

              return ''
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
      >
        <Button variant="contained" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
