import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { parse } from 'date-fns'

export const ScheduleRow = ({
  day,
  index,
  defaultValues = {
    opening: '',
    closing: '',
    patronCheckInPointBonusMultiplier: 0,
  },
  openLabel = 'Open',
  closedLabel = 'Closed',
}: {
  day: string
  index: number
  defaultValues?: {
    opening: string
    closing: string
    patronCheckInPointBonusMultiplier: number
  }
  openLabel?: string
  closedLabel?: string
}) => {
  const { register, unregister, setValue, watch } = useFormContext()
  const [open, setOpen] = useState(
    defaultValues.patronCheckInPointBonusMultiplier === 2
  )

  useEffect(() => {
    if (!open) {
      unregister(`schedules[${index}]`)
    }
  }, [open])

  const openingTime = watch(`schedules[${index}.opening]`)
  const closingTime = watch(`schedules[${index}.closing]`)

  const formatDateToJJTime = (time: Date) => {
    return time.toTimeString().slice(0, 8)
  }

  return (
    <Box>
      <input
        type="hidden"
        {...register(`schedules[${index}.dayName]`, {
          required: true,
          value: day,
        })}
      ></input>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={1} alignSelf={'center'}>
          <Typography className="min-w-[100px]" variant="body-1">
            {day}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          alignSelf={'center'}
          textAlign={{
            xs: 'right',
            sm: 'center',
          }}
        >
          <FormControlLabel
            sx={{ margin: '0px' }}
            control={
              <Switch
                checked={open}
                color="success"
                data-testId={`${day}-switch`}
              />
            }
            onChange={() => setOpen(!open)}
            label={open ? openLabel : closedLabel}
          />
        </Grid>
        {open && (
          <Grid item xs={12} sm={8}>
            <Box
              display={'flex'}
              sx={{
                mt: {
                  xs: '12px',
                },
                mb: {
                  xs: '8px',
                },
              }}
            >
              <Grid item xs={5} md={3}>
                <TimePicker
                  timeSteps={{ hours: 1, minutes: 10 }}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  defaultValue={
                    defaultValues.opening
                      ? parse(defaultValues.opening, 'HH:mm:ss', new Date())
                      : undefined
                  }
                  {...register(`schedules[${index}.opening]`, {
                    required: true,
                    value: openingTime ?? defaultValues.opening,
                    onChange() {},
                  })}
                  onChange={(value: string | Date | null) => {
                    setValue(
                      `schedules[${index}.opening]`,
                      formatDateToJJTime(value as Date)
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                md={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body-1">to</Typography>
              </Grid>
              <Grid item xs={5} md={3}>
                <TimePicker
                  timeSteps={{ hours: 1, minutes: 10 }}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  defaultValue={
                    defaultValues.closing
                      ? parse(defaultValues.closing, 'HH:mm:ss', new Date())
                      : undefined
                  }
                  {...register(`schedules[${index}.closing]`, {
                    required: true,
                    value: closingTime ?? defaultValues.closing,
                    onChange() {},
                  })}
                  onChange={(value: string | Date | null) => {
                    setValue(
                      `schedules[${index}.closing]`,
                      formatDateToJJTime(value as Date)
                    )
                  }}
                />
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
