import { Page } from 'components/Shared/Page'
import { RewardProgramDetailHeader } from '../RewardsProgramDetailsHeader/RewardProgramDetailHeader'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { useGetCheckInPolicyByRewardsProgramsId } from 'hooks/api/RewardProgram/useGetCheckInPolicyByRewardsProgramsId'
import { useParams } from 'react-router-dom'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { SendRewardSection } from './SendRewardSection'

export const RewardProgramSendRewardPage = () => {
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById(Number(rewardProgramId))

  const checkInPolicyQuery = useGetCheckInPolicyByRewardsProgramsId(
    Number(rewardProgramId)
  )
  if (rewardProgramQuery.isPending || checkInPolicyQuery.isPending) {
    return <ActivityIndicator size="fullPage" />
  }

  if (rewardProgramQuery.isError || checkInPolicyQuery.isError) {
    return <p>An error occurred.</p>
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/Rewards/RewardPrograms/${rewardProgramId}/SendReward`}
        />
      }
    >
      <SendRewardSection />
    </Page>
  )
}
