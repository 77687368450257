import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { ActivityButton } from 'components/ActivityButton'
import { colorPrimitives } from 'components/Theme'
import { useDeleteLicensedEstablishmentUserById } from 'hooks/api/LicensedEstablishmentUser/useDeleteLicensedEstablishmentUserById'
import { useSnackbar } from 'stores/useSnackbar'
import { type LicensedEstablishmentUser } from 'types/api'

interface DeleteLEConnectUserModalProps {
  isModalOpen: boolean
  data?: LicensedEstablishmentUser | null
  toggleIsOpen: () => void
}

export const DeleteLEConnectUserModal = ({
  isModalOpen,
  toggleIsOpen,
  data,
}: DeleteLEConnectUserModalProps) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const handleDelete = (id?: number | null) => {
    useDeleteUserMutation.mutate({ id })
  }

  const useDeleteUserMutation = useDeleteLicensedEstablishmentUserById({
    onSuccess: () => {
      setSnackbarMessage(`J&J Connect User successfully deleted`, 'success')
      toggleIsOpen()
    },
    onError: () => {
      setSnackbarMessage(
        `An error has occured while deleting the J&J Connect User`,
        'error'
      )
      toggleIsOpen()
    },
  })

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '700px' } }}
      onClose={toggleIsOpen}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={{ xs: 2, sm: 4 }} py={{ xs: 3, sm: 4 }}>
          <Typography variant="h3">Delete J&J Connect User</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="pt-4">
          <Typography color={colorPrimitives.redGaming}>
            Deleting this user will remove them from all associated accounts and
            from the database.
          </Typography>
        </Box>
        <Box paddingBottom={2} paddingTop={3}>
          <Typography variant="h3" fontWeight={'bold'}>
            {data?.firstName} {data?.lastName}
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {data?.email}
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {data?.licensedEstablishments?.[0]
              ? data?.licensedEstablishments[0].type
              : ''}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
      >
        <Button variant="text" onClick={toggleIsOpen}>
          Cancel
        </Button>
        <ActivityButton
          variant="contained"
          onClick={() => handleDelete(data?.id)}
          active={useDeleteUserMutation.isPending}
        >
          Delete {data?.firstName} {data?.lastName}
        </ActivityButton>
      </DialogActions>
    </Dialog>
  )
}
