import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  createFilterOptions,
  Popper,
  Autocomplete,
  TextField,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { omit } from 'lodash'
import { useGetParticipatingLocations } from 'hooks/api/Sweepstake/useGetParticipatingLocations'
import { useDropdownParticipatingLocationsStore } from 'stores/useDropdownParticipatingLocationStore'
import { useGetAllLicensedEstablishments } from 'hooks/api/LicensedEstablishment/useGetAllLicensedEstablishments'
import { useMemo } from 'react'

const MAX_LOCATIONS = 200

interface SelectParticipatingLocationProps {
  fullWidth?: boolean
  required?: boolean
  error?: boolean
  label?: string
  name?: string
  defaultValue?: number | null
  placeholder?: string
  inputProps?: Record<string, any>
  idsToFilter?: number[]
  contestId: number
}

export const SelectParticipatingLocation = ({
  contestId,
  label,
  placeholder,
  required,
  name = String(contestId),
  error,
  defaultValue,
  inputProps,
  idsToFilter,
  ...otherProps
}: SelectParticipatingLocationProps) => {
  const setDropdownParticipatingLocationsValue =
    useDropdownParticipatingLocationsStore(
      (state) => state.setdropdownParticipatingLocationsValue
    )

  const dropdownParticipatingLocationsValue =
    useDropdownParticipatingLocationsStore(
      (state) => state.dropdownParticipatingLocationsValue
    )

  const participatingLocationsQuery = useGetParticipatingLocations({
    contestId: Number(contestId),
  })

  const licensedEstablishments =
    participatingLocationsQuery?.data
      ?.filter((le) => le.contest?.status === 'Active')
      .map((le) => le.licensedEstablishment) ?? []

  const filteredIds = useMemo(() => {
    return licensedEstablishments?.flatMap((item) => item?.id)
  }, [licensedEstablishments]).map((x) => x)

  const { data: allLicensedEstablishments } = useGetAllLicensedEstablishments()

  const licensedEstablishmentsFromIds = useMemo(() => {
    return (
      (Array.isArray(allLicensedEstablishments) &&
        allLicensedEstablishments?.filter(
          (le) => le.active && !le.excludedFromSweepstakes
        )) ||
      []
    )
  }, [allLicensedEstablishments, filteredIds])

  const handleChange = (newValue: number) => {
    setDropdownParticipatingLocationsValue(String(newValue))
  }

  return (
    <Box>
      <FormControl>
        <InputLabel id={`${name}-label`} htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          defaultValue={defaultValue ?? null}
          rules={{ required: false }}
          render={({ field: { onChange, value, ref } }) => (
            <Autocomplete
              filterOptions={createFilterOptions({
                matchFrom: 'any',
                limit: MAX_LOCATIONS,
              })}
              renderInput={(params) => (
                <TextField
                  placeholder={placeholder}
                  error={error}
                  {...params}
                  {...inputProps}
                  inputRef={ref}
                />
              )}
              id={name}
              disablePortal
              options={
                contestId !== 0
                  ? filteredIds.length > 0
                    ? (licensedEstablishmentsFromIds.filter((x) =>
                        filteredIds?.includes(x.id)
                      ) ?? [])
                    : licensedEstablishmentsFromIds
                  : []
              }
              getOptionLabel={(option) => option.standardName ?? ''}
              ListboxProps={{ sx: { maxHeight: '250px' } }}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  popperOptions={{
                    modifiers: [
                      { name: 'flip', options: { fallbackPlacements: [] } },
                    ],
                  }}
                  placement="bottom"
                />
              )}
              renderOption={(props, ca) => (
                <MenuItem
                  key={`le-account-option-${Number(ca.id)}`}
                  value={String(ca.id)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  {...omit(props, 'className', 'key')}
                >
                  {ca.standardName}
                </MenuItem>
              )}
              {...otherProps}
              onChange={(_event, newValue) => {
                onChange(newValue?.id ?? null)
                handleChange(newValue?.id ?? 0)
              }}
              value={
                dropdownParticipatingLocationsValue !== ''
                  ? (licensedEstablishmentsFromIds?.find(
                      (account) => account.id === value
                    ) ?? null)
                  : ''
              }
            />
          )}
        />
      </FormControl>
    </Box>
  )
}
