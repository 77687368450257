import { useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { arrayRequestBody } from 'openapi-typescript-fetch'
import { type ContestLeRequest } from 'types/api'

export const useManageParticipatingLocations = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => Promise<void>
  onError?: () => void
}) => {
  const post = useTypedApiClient({
    path: '/admin/contests/{contestId}/licensed-establishments/participating',
    method: 'post',
  })

  return useMutation({
    mutationKey: ['/sweepstakes/{id}/participating-locations'],
    mutationFn: async ({
      contestId,
      req,
    }: {
      contestId: number
      req: ContestLeRequest[]
    }) => {
      return await post(arrayRequestBody(req, { contestId }))
    },
    onSuccess: async () => {
      if (onSuccess) {
        await onSuccess()
      }
    },
    onError,
  })
}
