import { useCallback, useMemo, useState } from 'react'
import { Box, Divider, Typography, Grid, Stack } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetRewardItems } from 'hooks/api/useGetRewardItems'
import { ImageCard } from 'components/ImageCard/ImageCard'
import { SelectRewardsFooter } from './SelectRewardsFooter'
import { type RewardsCatalogReward } from 'utils/rewardsCatalogRewards'
import { isNil } from 'lodash'
import { type Reward, type RewardsCatalog } from 'types/api'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'
import { SearchField } from 'components/SearchField'

interface SelectRewardsProps {
  onClickNext: () => void
  rewardsCatalog: RewardsCatalog
  onClickCancel: () => void
  rewardsCatalogRewards: Map<number, RewardsCatalogReward>
  addRewardCatalogReward: (reward: Reward) => void
  removeRewardCatalogReward: (
    rewardId: number,
    validateAtLeast1?: boolean
  ) => void
}

export const SelectRewards = ({
  onClickNext,
  rewardsCatalog,
  onClickCancel,
  rewardsCatalogRewards,
  addRewardCatalogReward,
  removeRewardCatalogReward,
}: SelectRewardsProps) => {
  const [searchString, setSearchString] = useState('')
  const rewardItemsQuery = useGetRewardItems()

  const onClickRewardCard = useCallback(
    (reward: Reward) => {
      const rewardId = Number(reward.id)
      if (rewardsCatalogRewards.has(rewardId)) {
        removeRewardCatalogReward(rewardId)
      } else {
        addRewardCatalogReward(reward)
      }
    },
    [rewardsCatalogRewards, addRewardCatalogReward, removeRewardCatalogReward]
  )

  const filteredItems = useMemo(() => {
    const data =
      rewardsCatalog.type === 'JJStoreCatalog'
        ? Array.isArray(rewardItemsQuery.data) &&
          rewardItemsQuery.data?.filter((x) => x.type === 'JJStoreItem')
        : Array.isArray(rewardItemsQuery.data) &&
          rewardItemsQuery.data?.filter((x) => x.type !== 'JJStoreItem')
    return data
      ? data
          .filter((ri) =>
            ri.name.toLowerCase().includes(searchString.toLowerCase())
          )
          .sort((a, b) => {
            if (a.name === b.name) return 0
            return a.name > b.name ? 1 : -1
          })
      : []
  }, [rewardItemsQuery.data, searchString])

  if (rewardItemsQuery.isPending) {
    return <ActivityIndicator />
  }

  if (rewardItemsQuery.isError) {
    return <ErrorIndicator />
  }

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title={`Select items to add to ${rewardsCatalog?.name}`}
            isSecondary={true}
            subtitle="Select one or multiple items from the list below to add them to the selected catalog."
          />
          <Divider />
        </Box>
      }
      footer={
        <SelectRewardsFooter
          onClickCancel={onClickCancel}
          onClickNext={onClickNext}
        />
      }
    >
      <Stack direction="column" rowGap="16px">
        <SearchField
          placeholder="Search Rewards Items"
          onChange={(e) => setSearchString(e.target.value)}
          fullWidth
          sx={{ my: '16px' }}
        />
        <Typography variant="subtitle1">
          <Box component="span" fontWeight="bold">
            {rewardsCatalogRewards.size}
          </Box>
          {' items selected'}
        </Typography>
        <Grid container rowSpacing={4} columnSpacing={4}>
          {filteredItems.map((reward) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={reward.id}>
              <ImageCard
                hideBoxShadow={true}
                testId={`card-${Number(reward.id)}`}
                title={reward.name}
                subtitle={`ID: ${Number(reward.id)}`}
                isSelected={rewardsCatalogRewards.has(Number(reward.id))}
                imageSource={
                  isNil(reward.publicImageUrl)
                    ? undefined
                    : reward.publicImageUrl
                }
                onClick={() => {
                  onClickRewardCard(reward)
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Page>
  )
}
