import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { type components } from 'api/playerPayback/api'
import { format, parseISO } from 'date-fns'
import { useGetNumberWinnerEntries } from 'hooks/api/Sweepstake/EntriesCount/useGetNumberWinnerEntries'
import { EditPatronInformation } from 'pages/Patrons/EditPatronInformation/EditPatronInformation'
import { isNil } from 'lodash'
import { formatPhoneNumber } from 'utils/phoneUtils'
import { WinnerNotesModal } from './WinnerNotesModal'
import { useQueryClient } from '@tanstack/react-query'

export const WinnerDetailsSection = ({
  winner,
}: {
  winner: components['schemas']['FullSweepstakeWinnerDTO']
}) => {
  const theme = useTheme()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const dob = winner.patron?.dob ? parseISO(winner.patron.dob) : null
  const winnerEntries = useGetNumberWinnerEntries({
    patronId: winner.patronId,
    contestId: winner.contestEntry?.contestId,
    licensedEstablishmentId:
      winner.sweepstakeDrawing?.type === 'Location'
        ? winner.contestEntry?.patronCheckIn?.licensedEstablishmentId
        : undefined,
  })
  const queryClient = useQueryClient()
  const { address1, address2, city, state, postalCode } =
    winner.patron?.addresses?.[0] ?? {}
  const toggleEditModalIsOpen = async () => {
    setIsEditModalOpen(!isEditModalOpen)
    // a bit hacky but this will force winner details to refresh in case they made changes in the patron edit modal
    await queryClient.invalidateQueries({
      queryKey: ['/sweepstake-winners', winner.id],
    })
  }
  const toggleNotesModalIsOpen = async () => {
    setIsNotesModalOpen(!isNotesModalOpen)
  }

  if (winnerEntries.isPending) {
    return (
      <Box>
        <CircularProgress />
        <Typography> Loading winnerEntries query...</Typography>
      </Box>
    )
  }

  return (
    <>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        mb={2}
        pt={8}
      >
        <Typography variant="h2" pb={2}>
          Winner Details
        </Typography>
        <Button
          sx={{ height: '100%', justifyContent: 'flex-end', minWidth: 10 }}
          onClick={toggleEditModalIsOpen}
        >
          Edit
        </Button>
      </Stack>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Drawing Number
            </Typography>
            <Typography variant="body1" pb={2}>
              {(winner.drawingSequence ?? 0) + 1}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {winner.patron?.firstName} {winner.patron?.lastName}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Birthdate
            </Typography>
            {!isNil(dob) && (
              <Typography variant="body1" pb={2}>
                {`${format(dob, 'P')}`}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Gender
            </Typography>
            <Typography variant="body1" pb={2}>
              {winner.patron?.gender ?? '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Tier Level
            </Typography>
            <Typography variant="body1" pb={2}>
              {winner.patron?.currentTier?.tier?.type ??
                winner.patronTier?.tier?.type ??
                'Member'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Total Entries by Winner
            </Typography>
            {winnerEntries.data !== undefined ? (
              <Typography variant="body1" pb={2}>
                {String(winnerEntries.data)}
              </Typography>
            ) : (
              <Typography variant="body1" pb={2}>
                Could not retrieve
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Email
            </Typography>
            <Typography variant="body1" pb={2}>
              {winner.patron?.email}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Phone Number
            </Typography>
            {winner.patron?.phoneNumber !== undefined && (
              <Typography variant="body1" pb={2}>
                {formatPhoneNumber(winner.patron.phoneNumber)}
              </Typography>
            )}
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Won from entries at
            </Typography>
            <Typography variant="body1" pb={2}>
              {
                winner.contestEntry?.patronCheckIn?.licensedEstablishment
                  ?.standardName
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Mailing Address
            </Typography>
            <Typography variant="body1" pb={2}>
              {address1 ?? '-'}
              {address2 && (
                <>
                  <br />
                  {address2}
                </>
              )}
              {(city ?? state ?? postalCode) && (
                <>
                  <br />
                  {city ? `${city}, ` : ''}
                  {state ? `${state}, ` : ''}
                  {postalCode ?? ''}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container columnGap={20}>
          <Grid item md={8}>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
            >
              <Typography variant="body2" pb={0.5} color="text.secondary">
                Notes
              </Typography>
              <Button
                sx={{
                  height: '100%',
                  justifyContent: 'flex-end',
                  minWidth: 10,
                }}
                onClick={toggleNotesModalIsOpen}
              >
                Edit
              </Button>
            </Stack>
            <Typography
              variant="body1"
              pb={2}
              border={{ md: `1px solid ${theme.palette.grey[200]}` }}
              borderRadius={'5px'}
              p={{ xs: 0, sm: 3 }}
            >
              {winner.notes}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {winner.patron && (
        <>
          <EditPatronInformation
            isModalOpen={isEditModalOpen}
            toggleModalIsOpen={toggleEditModalIsOpen}
            patron={winner.patron}
          />
          <WinnerNotesModal
            isModalOpen={isNotesModalOpen}
            toggleModalIsOpen={toggleNotesModalIsOpen}
            sweepstakeWinner={winner}
          />
        </>
      )}
    </>
  )
}
