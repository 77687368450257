import { Box, Button, Stack, Typography } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { DataTable } from 'components/Shared/DataTable'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useLayout } from 'hooks/useLayout'
import { Link, useNavigate } from 'react-router-dom'
import {
  dataTableAddressFormatter,
  dataTableIdFormatter,
  minimumSearchLength,
} from 'utils/util'
import { isNil } from 'lodash'
import { useGetAllLicensedEstablishments } from 'hooks/api/LicensedEstablishment/useGetAllLicensedEstablishments'
import { SearchField } from 'components/SearchField'
import { useGetLicensedEstablishmentSearch } from 'hooks/api/LicensedEstablishment/useGetLicensedEstablishmentSearch'
import { useDebounce } from '@uidotdev/usehooks'
import { useState } from 'react'
import { Colors } from 'components/Theme'

const defaultColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'standardName',
      minWidth: 300,
      headerName: 'DBA Name',
      flex: 2,
    },
    {
      field: 'licenseNumber',
      minWidth: 50,
      headerName: 'IGB Number',
      flex: 1,
      valueFormatter: dataTableIdFormatter(isMobile),
    },
    {
      field: 'smartID',
      minWidth: 50,
      headerName: 'SMART ID',
      flex: 1,
    },
    {
      field: 'addresses',
      minWidth: 100,
      headerName: 'Address',
      valueFormatter: dataTableAddressFormatter(),
      flex: 3,
    },
    {
      field: 'active',
      minWidth: 100,
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Box
          className="p-2 py-1 rounded-sm"
          bgcolor={
            params.row.active
              ? Colors.licensedEstablishmentStatus.background.active
              : Colors.licensedEstablishmentStatus.background.inactive
          }
          color={
            params.row.active
              ? Colors.licensedEstablishmentStatus.text.active
              : Colors.licensedEstablishmentStatus.text.inactive
          }
        >
          <Typography
            sx={{ textTransform: 'uppercase' }}
            variant="body-1"
            fontWeight={'bold'}
          >
            {params.row.active ? 'ACTIVE' : 'INACTIVE'}
          </Typography>
        </Box>
      ),
    },
  ]
}

export const LicensedEstablishmentListPage = () => {
  const navigate = useNavigate()
  const { isMobile } = useLayout()
  const [searchKey, setSearchKey] = useState('')
  const debouncedSearchKey = useDebounce(searchKey, 500)
  const licensedEstablishmentQuery = useGetAllLicensedEstablishments()
  const licensedEstablishmentSearchQuery = useGetLicensedEstablishmentSearch({
    searchKey: debouncedSearchKey,
  })

  const preferredQuery =
    searchKey.length >= minimumSearchLength
      ? licensedEstablishmentSearchQuery
      : licensedEstablishmentQuery

  if (preferredQuery.isError) {
    return <Typography>Something went wrong</Typography>
  }

  return (
    <Page
      header={
        <PageHeader
          title="Licensed Establishments"
          eyebrow="CLIENT MANAGEMENT"
        />
      }
    >
      <Stack
        direction={{ md: 'row', sm: 'column', xs: 'column' }}
        gap={{ md: 0, sm: 2, xs: 2 }}
        mb={{ md: 4, sm: 2, xs: 2 }}
        justifyContent={'space-between'}
      >
        <SearchField
          placeholder="Search Licensed Establishments"
          onChange={(e) => setSearchKey(e.target.value)}
          fullWidth
        />
        <Link to="/LicensedEstablishment/New">
          <Button variant="contained">Create New LE</Button>
        </Link>
      </Stack>
      {preferredQuery.isPending || isNil(preferredQuery.data) ? (
        <ActivityIndicator />
      ) : (
        <DataTable
          data-testid="custom-data-table"
          rows={preferredQuery.data as any[]}
          columns={defaultColumns(isMobile)}
          onRowClick={(params) => {
            if (params.id !== null) {
              navigate(`/LicensedEstablishments/${params.id}`)
            }
          }}
        />
      )}
    </Page>
  )
}
