import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { PatronDetailsHeader } from '../DetailsHeader/PatronDetailsHeader'
import { PatronCheckInsContainer } from './PatronCheckInsContainer'

export const PatronCheckInHistoryPage = () => {
  const { id: patronAccountId } = useParams()

  if (!patronAccountId) {
    return 'Patron Account Id cannot be null'
  }

  const patronQuery = useGetPatronById(Number(patronAccountId))

  if (patronQuery.isPending) {
    return <ActivityIndicator />
  }

  if (patronQuery.isError || isNil(patronQuery.data)) {
    return <p>An error occurred.</p>
  }

  return (
    <PatronDetailsHeader
      patron={patronQuery.data}
      currentTab={`/PatronAccounts/${patronAccountId}/CheckInHistory`}
    >
      <PatronCheckInsContainer patronId={Number(patronAccountId)} />
    </PatronDetailsHeader>
  )
}
