import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetWinnerById = (id: number) => {
  const get = useTypedApiClient({
    path: '/admin/sweepstake-winners/{id}',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/sweepstake-winners', id],
    queryFn: async () => {
      const { data } = await get({ id })
      return data
    },
  })
}
