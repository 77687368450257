import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { minimumSearchLength } from 'utils/util'

export const useGetFilteredLicensedEstablishmentUsers = (searchKey: string) => {
  const get = useTypedApiClient({
    path:
      searchKey.length >= minimumSearchLength
        ? '/admin/licensed-establishments/users/search'
        : '/admin/licensed-establishments/users',
    method: 'get',
  })

  return useQuery({
    queryKey: ['users', searchKey],

    queryFn: async () => {
      const { data } = await get({ searchKey })
      return data
    },
  })
}
