import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { Counter } from 'components/Counter/Counter'
import type { PointsLedgerSummary, RewardsProgram } from 'types/api'
import {
  Box,
  Button,
  MenuItem,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { usePostManualPointsLedger } from 'hooks/api/usePostManualPointsLedger'
import { ActivityButton } from 'components/ActivityButton'
import { SearchField } from 'components/SearchField'
import RewardProgramIcon from 'assets/nav_icons/rewards_program.svg?react'
import { useQuery } from '@tanstack/react-query'
import { type ApiError } from 'openapi-typescript-fetch'
import { useDebounce } from '@uidotdev/usehooks'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { colorPrimitives } from 'components/Theme'
import CloseIcon from 'assets/close-filled.svg?react'
import { colors } from 'components/ThemeProvider'

export const AddRewardProgramModal = ({
  patronId,
  isModalOpen,
  toggleModalIsOpen,
  pointLedgerSummary,
  rewardProgramIds,
}: {
  patronId: number
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  pointLedgerSummary: PointsLedgerSummary | undefined
  rewardProgramIds: Array<number | undefined> | undefined
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '60%'
  const { handleSubmit } = useForm()
  const [messageError, setMessageError] = useState('')
  const [pointsToGrant, setPointsToGrant] = useState(0)
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const [query, setQuery] = useState('')
  const [selectedRewardProgram, setSelectedRewardProgram] =
    useState<RewardsProgram>()
  const debouncedQuery = useDebounce(query, 500)

  const searchRewardPrograms = useTypedApiClient({
    path: '/admin/rewards-programs/search',
    method: 'get',
  })

  const grantPoints = handleSubmit(async () => {
    postManualPointsLedgerMutation.mutate({
      rewardsProgramId: Number(selectedRewardProgram?.id),
      amount: pointsToGrant,
    })
    setQuery('')
  })

  const handleRewardProgramClick = (e: any) => {
    let programId = ''
    if (e.target.nodeName === 'P') {
      const splitText = e.target.innerText.split(' ')
      programId = splitText[splitText.length - 1]
    }
    const programById = rewardProgramsQuery.data?.filter(
      (rewardProgram) => rewardProgram.id === Number(programId)
    )[0]
    setSelectedRewardProgram(programById)
  }

  const onCloseRewardProgram = () => {
    setQuery('')
    setSelectedRewardProgram(undefined)
  }

  const rewardProgramsQuery = useQuery<RewardsProgram[], ApiError>({
    queryKey: ['rewardPrograms', debouncedQuery],
    queryFn: async () => {
      if (debouncedQuery.length >= 2) {
        return (await searchRewardPrograms({ query: debouncedQuery })).data
      } else {
        return []
      }
    },
  })

  const filteredRewardPrograms = rewardProgramsQuery.data?.filter(
    (item) =>
      !rewardProgramIds?.includes(item.id) &&
      item.type !== 'Global' &&
      item.active === true
  )

  const resetAll = () => {
    toggleModalIsOpen()
    setMessageError('')
    setPointsToGrant(0)
    setQuery('')
    setSelectedRewardProgram(undefined)
  }

  const setPointsToGrantWithValidation = (amount: number) => {
    if (amount > 0) {
      setPointsToGrant(amount)
      return
    }

    if (Number(pointLedgerSummary?.balance) + amount >= 0) {
      setPointsToGrant(amount)
    }
  }

  const postManualPointsLedgerMutation = usePostManualPointsLedger({
    patronId,
    onSuccess: (_, __) => {
      setSnackbarMessage(
        `${selectedRewardProgram?.name} Points balance has been updated.`
      )
      resetAll()
    },
    onError: (error, _) => {
      setMessageError(error.message)
    },
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '45%',
        height: heightModal,
      }}
    >
      <Box>
        <form onSubmit={grantPoints}>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={'Add Location Reward Program'}
          />
          <ModalFullBody heightModal={heightModal}>
            {messageError && (
              <Box className="w-full mb-[3%]">
                <FormFieldErrorMessage
                  message={messageError}
                  variant="contained"
                />
              </Box>
            )}
            <Box>
              {/* Search Reward Program Name Section */}
              <Typography variant="h6">Search Reward Program Name</Typography>
              <Typography variant="body-2" color={'rgba(44, 44, 39, 0.68)'}>
                Make sure to use a minimum of at least 2 characters per word
                when searching.
              </Typography>
              <SearchField
                placeholder="Start typing to search"
                inputProps={{ 'data-testid': 'search-input' }}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                fullWidth
                sx={{ my: '16px' }}
              />

              {filteredRewardPrograms?.length && !selectedRewardProgram ? (
                <Paper variant="outlined" sx={{ mt: '7px', width: '520px' }}>
                  {filteredRewardPrograms?.map(
                    (rewardProgram: { name: string; id: number }) => {
                      let resultString = `${rewardProgram.name} - ${rewardProgram.id}`
                      resultString = resultString.replace(
                        query,
                        `<strong>${query}</strong>`
                      )
                      return (
                        <MenuItem
                          data-testid="search-result"
                          sx={{ padding: 0 }}
                          key={rewardProgram.id}
                          value={rewardProgram.id}
                          onClick={handleRewardProgramClick}
                        >
                          <p
                            className="py-1.5 px-4 w-full h-full"
                            dangerouslySetInnerHTML={{ __html: resultString }}
                          />
                        </MenuItem>
                      )
                    }
                  )}
                </Paper>
              ) : null}
              {selectedRewardProgram && (
                <>
                  <Box
                    sx={{
                      backgroundColor: colorPrimitives.lightGray,
                      p: '12px',
                    }}
                    maxWidth={'520px'}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row items-center">
                        <SvgIcon
                          sx={{
                            color: colors.text.primary,
                            transform: 'translate(0, -2px)',
                            height: '40px',
                            width: '40px',
                            marginRight: '10px',
                          }}
                        >
                          <RewardProgramIcon />
                        </SvgIcon>
                        <div className="flex flex-col">
                          <Typography variant="body-1">
                            {selectedRewardProgram?.name}
                          </Typography>
                          <Typography variant="body-1" color="text.secondary">
                            {selectedRewardProgram?.id}
                          </Typography>
                        </div>
                      </div>
                      <Box
                        onClick={onCloseRewardProgram}
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <SvgIcon
                          sx={{
                            transform: 'translate(0, -2px)',
                            height: '24px',
                            width: '24px',
                          }}
                        >
                          <CloseIcon />
                        </SvgIcon>
                      </Box>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      mt: '2px',
                      backgroundColor: colorPrimitives.lightGray,
                      p: '12px',
                    }}
                    maxWidth={'520px'}
                  >
                    <div className="grid grid-cols-2 gap-2 h-full">
                      <div className="flex items-center">
                        <div>
                          <Typography variant="body-1" fontWeight={'bold'}>
                            Add points
                          </Typography>
                        </div>
                      </div>
                      <Counter
                        amount={pointsToGrant}
                        setAmount={setPointsToGrantWithValidation}
                        minimumAmount={0}
                      />
                    </div>
                  </Box>
                </>
              )}
            </Box>
          </ModalFullBody>
          <ModalFullFooter>
            <Button onClick={resetAll}>Cancel</Button>
            <ActivityButton
              active={postManualPointsLedgerMutation.isPending}
              type="submit"
              variant="contained"
              disabled={pointsToGrant <= 0}
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </Box>
    </Modal>
  )
}
