import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetExportForWinnersByContestId = ({
  contestId,
}: {
  contestId: number
}) => {
  const get = useTypedApiClient({
    path: '/admin/exports/winners/{contestId}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/exports/winners/{contestId}', contestId],
    queryFn: async () => {
      const { data } = await get({ contestId })
      return data
    },
  })
}
