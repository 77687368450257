import { type paths } from 'api/playerPayback/api'
import { useAccessTokenContext } from 'contexts/AccessTokenContext'
import { Fetcher, type TypedFetch } from 'openapi-typescript-fetch'

const getHeaders = (disableAuth: boolean, token: string) => {
  if (disableAuth) {
    return undefined
  }

  return {
    Authorization: `Bearer ${token}`,
  }
}

export const useTypedApiClient = <
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
>({
  path,
  method,
  disableAuth = false,
}: {
  path: PathT
  method: MethodT
  disableAuth?: boolean
}): TypedFetch<paths[PathT][MethodT]> => {
  const { accessToken } = useAccessTokenContext()
  const fetcher = Fetcher.for<paths>()

  fetcher.configure({
    baseUrl: import.meta.env.VITE_ADMIN_API_BASE_URL,
    init: {
      headers: getHeaders(disableAuth, accessToken ?? ''),
    },
  })

  // Here, cast the result to the exact expected return type
  return fetcher
    .path(path)
    .method(method as keyof paths[PathT]) // Ensure method is a valid key
    .create({}) as TypedFetch<paths[PathT][MethodT]> // Explicitly cast the return type
}
