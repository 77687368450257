import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Box, MenuItem, Select } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { TextField } from 'components/Shared/TextField'
import Typography from '@mui/material/Typography'
import { useApiClient } from 'hooks/useApiClient'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'stores/useSnackbar'
import { object, string, mixed } from 'yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import type { RewardsCatalogType } from 'types/api'

interface FormData {
  name: string
  type: RewardsCatalogType
}

const RewardCatalogSchema = object({
  name: string().required('Must enter a reward catalog name'),
  type: mixed<RewardsCatalogType>()
    .oneOf(['JJStoreCatalog', 'LocationCatalog'], 'Invalid catalog type')
    .required('Type is required'),
})

export const RewardCatalogsNewPage = () => {
  const { post } = useApiClient()
  const navigate = useNavigate()
  const setMessage = useSnackbar((state) => state.setMessage)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RewardCatalogSchema),
  })

  const mutate = useMutation({
    mutationKey: ['rewards/catalog/create'],
    mutationFn: async ({ name, type }: FormData) => {
      const response = await post('/admin/rewards-catalogs', {
        params: { header: undefined },
        body: {
          name,
          type,
        },
      })
      return response
    },
    onSuccess: (response) => {
      navigate(`/Rewards/RewardCatalogs/${response.data?.id}`)
      setMessage('Reward Catalog was successfully created.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
    onError: () => {
      setMessage('Error creating reward catalog', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  if (mutate.isPending) {
    return <ActivityIndicator />
  }

  if (mutate.isError) {
    return <h1>An error occured.</h1>
  }

  const createRewardsCatalogWrapper = handleSubmit((data) => {
    mutate.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title={'Create a New Reward Catalog'}
          subtitle={`You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.`}
          backText="Reward Catalogs"
          backPath="/Rewards/RewardCatalogs"
          isSecondary={true}
        ></PageHeader>
      }
    >
      <form onSubmit={createRewardsCatalogWrapper}>
        <TextField
          label="Reward Catalog Name"
          placeholder="Enter reward catalog name"
          id="new-rewards-catalog-name-text-box"
          required
          error={isPresent(errors.name)}
          {...register('name', { required: true })}
        />
        <Typography fontWeight="bold" className="pt-4 pb-2">
          Reward Catalog Type <span style={{ color: '#CC2027' }}>*</span>
        </Typography>
        <Select
          className="w-[200px]"
          id="type"
          {...register('type')}
          required={true}
          variant="outlined"
          defaultValue="LocationCatalog"
        >
          <MenuItem value="LocationCatalog">Location Catalog</MenuItem>
          <MenuItem value="JJStoreCatalog">J&J Store Catalog</MenuItem>
        </Select>
        {errors.name?.message && (
          <FormFieldErrorMessage message={errors.name.message} />
        )}
        <Box
          sx={{
            pt: '24px',
            display: 'flex',
            flexDirection: 'row',
            alignContent: { xs: 'between' },
            columnGap: { xs: '16px', sm: '24px' },
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: { xs: '171px', sm: '104px' } }}
            onClick={() => {
              navigate('/Rewards/RewardCatalogs')
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{ width: { xs: '171px', sm: '104px' } }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Page>
  )
}
