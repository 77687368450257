import { useEffect, useState } from 'react'
import { Button, Box } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid'
import { DataTable } from 'components/Shared/DataTable'
import { Modal } from 'components/Modal/Modal'
import { useLayout } from 'hooks/useLayout'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { useGetAllLicensedEstablishments } from 'hooks/api/LicensedEstablishment/useGetAllLicensedEstablishments'
import { type Campaign } from 'types/api'
import { type RequiredId } from 'utils/typeUtils'

interface ViewLocationsModalProps {
  campaign: RequiredId<Campaign>
  isModalOpen: boolean
  toggleModalIsOpen: () => void
}

interface MappedEstablishment {
  name: string
  licenseNumber: string
}

export const ViewLocationsModal = ({
  campaign,
  isModalOpen,
  toggleModalIsOpen,
}: ViewLocationsModalProps) => {
  const { isMobile } = useLayout()
  const { data: allLicensedEstablishments } = useGetAllLicensedEstablishments()
  const [mappedEstablishments, setMappedEstablishments] = useState<
    MappedEstablishment[]
  >([])
  const heightModal = isMobile ? '100%' : '75%'

  useEffect(() => {
    if (allLicensedEstablishments) {
      const mappedEstablishments = campaign.licensedEstablishments?.map(
        (le) => {
          const matchingEstablishment =
            Array.isArray(allLicensedEstablishments) &&
            allLicensedEstablishments?.find(
              (allLE: { id: number }) => allLE.id === le.licensedEstablishmentId
            )
          return {
            name: matchingEstablishment?.standardName
              ? matchingEstablishment.standardName
              : '',
            licenseNumber: matchingEstablishment
              ? matchingEstablishment.licenseNumber
              : '',
          }
        }
      )
      if (mappedEstablishments) {
        setMappedEstablishments(mappedEstablishments)
      }
    }
  }, [allLicensedEstablishments])

  const getColumns = (): GridColDef[] => [
    { field: 'name', headerName: 'Name', minWidth: 300, flex: 1 },
    {
      field: 'licenseNumber',
      headerName: 'License Number',
      minWidth: 100,
      maxWidth: 200,
      flex: 1,
    },
  ]

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <ModalFullHeader title="Locations" toggleIsOpen={toggleModalIsOpen} />
      <ModalFullBody heightModal={heightModal}>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <DataTable
            data-testid="data-table"
            getRowId={(row) => row.licenseNumber}
            sortFieldName={'name'}
            rowHeight={65}
            columns={getColumns()}
            rows={mappedEstablishments}
          />
        </Box>
      </ModalFullBody>
      <ModalFullFooter>
        <Button variant="text" onClick={toggleModalIsOpen}>
          Close
        </Button>
      </ModalFullFooter>
    </Modal>
  )
}
