import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import { Alert, InputLabel, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { ActivityButton } from 'components/ActivityButton'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage/FormFieldErrorMessage'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { usePutSweepstakeWinner } from 'hooks/api/Sweepstake/usePutSweepstakeWinners'
import { useLayout } from 'hooks/useLayout'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'stores/useSnackbar'
import type { SweepstakeWinner } from 'types/api'
import { object, string } from 'yup'

const SweepstakeWinnerSchema = object({
  notes: string(),
})

export const WinnerNotesModal = ({
  isModalOpen,
  toggleModalIsOpen,
  sweepstakeWinner,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  sweepstakeWinner: SweepstakeWinner
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '50%'
  const formMethods = useForm({
    resolver: yupResolver(SweepstakeWinnerSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const putSweepstakeWinner = usePutSweepstakeWinner({
    onSuccess: () => {
      setSnackbarMessage(`Winner notes have been updated.`)
      toggleModalIsOpen()
    },
    onError: () => {
      setSnackbarMessage(`Error: Cannot save winner notes`, 'error')
    },
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    putSweepstakeWinner.mutate({
      id: sweepstakeWinner.id,
      contestEntryId: sweepstakeWinner.contestEntryId,
      patronId: sweepstakeWinner.patronId,
      status: sweepstakeWinner.status,
      sweepstakeDrawingId: sweepstakeWinner.sweepstakeDrawingId,
      drawingSequence: sweepstakeWinner.drawingSequence,
      resultType: sweepstakeWinner.resultType,
      resultNote: sweepstakeWinner.resultNote,
      validatedBy: sweepstakeWinner.validatedBy,
      validatedOn: sweepstakeWinner.validatedOn,
      redrawReason: sweepstakeWinner.redrawReason,
      dateDrawn: sweepstakeWinner.dateDrawn,
      locationValidationStartDate: sweepstakeWinner.locationValidationStartDate,
      affidavitCompletedAt: sweepstakeWinner.affidavitCompletedAt,
      dateRedrawnAt: sweepstakeWinner.dateRedrawnAt,
      notes: formData.notes,
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleModalIsOpen}
      sx={{
        width: isMobile ? '100%' : '500px',
        height: heightModal,
      }}
    >
      <form>
        <ModalFullHeader
          toggleIsOpen={toggleModalIsOpen}
          title="Winner Notes"
          subTitle={`${sweepstakeWinner.patron?.firstName} ${sweepstakeWinner.patron?.lastName}`}
        />
        <ModalFullBody heightModal={heightModal}>
          <div className="w-full">
            {!!Object.keys(errors).length && (
              <Alert severity="error">Please correct errors to continue</Alert>
            )}
            <div className="py-3">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }}>
                Notes
              </InputLabel>
              <div className="w-full">
                <TextField
                  defaultValue={sweepstakeWinner.notes}
                  multiline={true}
                  minRows={5}
                  fullWidth
                  placeholder="Notes"
                  {...register('notes')}
                  error={isPresent(errors.notes)}
                />
                {errors.notes?.message && (
                  <FormFieldErrorMessage message={errors.notes.message} />
                )}
              </div>
            </div>
          </div>
        </ModalFullBody>
        <ModalFullFooter>
          <div className="flex space-x-2">
            <Button onClick={toggleModalIsOpen}>Cancel</Button>
            <ActivityButton
              variant="contained"
              active={putSweepstakeWinner.isPending}
              onClick={handleSubmitWrapper}
            >
              Save
            </ActivityButton>
          </div>
        </ModalFullFooter>
      </form>
    </Modal>
  )
}
